import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { ComponentsLibraries } from "../../../../../../services/common";
import DragItemApp from "../../../../../ComponentsLibrary/DragItemApp";
import DropTargetApps from "../../../services/DropTargetApps";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import imxtools from "imxtools";
import { handleNavigateTo, handleMouseEnter, handleMouseLeave } from "./";
import DropTarget from "../../../services/DropTarget";
import Kpi from "../../Kpi";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { useContext } from "react";
import { AppContext } from "../../../../../../services/AppContext";

function RenderKpis(
  store,
  readOnly = true,
  pages = {},
  path = [],
  currentPage = 0,
  PageContent = {},
  hoverIn = "",
  setHoverIn,
  theItem = false,
  setTheItem,
  Kpis = [],
  setKpis,
  hoverStates = [],
  setHoverStates,
  currentPath
) {
  const kpis = imxtools?.toArray(PageContent?.kpi ?? []);
  const { setKpiDetail } = useContext(AppContext);
  const numKpi = kpis?.length;

  return (
    <DropTargetApps
      className="drop-kpi-zone"
      dropPath={path}
      accept={Object.keys(ComponentsLibraries?.kpi)}
      dropTargetName={"kpi"}
      pages={pages}
      currentPage={currentPage}
    >
      {(kpis.length > 0 ? kpis : [{}])?.map(function (kpi, index) {
        return (
          <DragItemApp
            className={`drag-kpi-page   ${
              isSelectedComponent(readOnly, [...path, "kpi"], currentPath)
                ? `selectedComponent`
                : `unSelectedComponent`
            } `}
            accept={["kpi"]}
            key={index}
            hoverIn={hoverIn}
            setHoverIn={setHoverIn}
            hoveredComponent={"app"}
            sort={true}
            fromComponentLib={false}
            theItem={theItem}
            setTheItem={setTheItem}
            listApps={Kpis}
            setListApps={setKpis}
            index={index}
            component={"kpi"}
            dragData={kpi}
            dragPath={
              numKpi === 1
                ? [...path, "kpi"]
                : [...path, "kpi", index?.toString()]
            }
            dragParentPath={path}
            dataSorted={Kpis}
            style={{
              width: !kpi?.type && "100%",
              height: kpis?.length === 0 && "10px",
              background: kpis?.length === 0 && "transparent",
            }}
            onClick={() => setKpiDetail(false)}
          >
            {kpis?.length > 0 && (
              <div
                className="kpi-page"
                onClick={(e) =>
                  handleComponentProp(
                    e,
                    numKpi === 1 ? null : index?.toString(),
                    kpi,
                    path,
                    store,
                    "kpi"
                  )
                }
                style={{
                  width: !kpi?.type && "100%",
                }}
              >
                <Kpi
                  data={kpi}
                  path={
                    numKpi === 1
                      ? [...path, "kpi"]
                      : [...path, "kpi", index?.toString()]
                  }
                  readOnly={readOnly}
                  currentPath={currentPath}
                  inPage={true}
                />
              </div>
            )}
          </DragItemApp>
        );
      })}
    </DropTargetApps>
  );
}

export default RenderKpis;
