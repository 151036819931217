import React, { useContext, useRef } from "react";
import { useDrop } from "react-dnd";
import { setIn } from "lodash-redux-immutability";
import { useSelector, useStore } from "react-redux";
import handleAddItem from "../handleAddItem";
import "./index.scss";
import { AppContext } from "../../../../../services/AppContext";
import _ from "lodash";
import { arraysAreEqual } from "../../../../../../../utils/util";

const moveItemToEndOfList = (oldData, itemOrder) => {
  const clonedData = _.cloneDeep(oldData);

  if (!Array.isArray(clonedData.app)) {
    console.error("Invalid oldData structure");
    return oldData;
  }

  const item = clonedData.app.find((item) => item.itemOrder === itemOrder);

  if (!item) {
    console.error("Item not found");
    return oldData;
  }

  clonedData.app = clonedData.app.filter(
    (item) => item.itemOrder !== itemOrder
  );

  clonedData.app.push(item);

  return clonedData;
};
const DropTargetRow = ({
  children,
  accept = [],
  style = {},
  dropPath = [],
  dropTargetName = "",
  indexRow,
  currentPage,
  pages,
  className = "",
  onClick = () => {},
}) => {
  const { insideInner, setInsideInner } = useContext(AppContext);

  let store = useStore();
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  const [, drop] = useDrop({
    accept: accept,

    drop: (item = { dragParentPath: [] }) => {
      if (arraysAreEqual(item.dragParentPath, insideInner)) {
        if (isNaN(item.dragParentPath[item.dragParentPath.length - 1])) {
          let OldData = _.get(skeleton, item.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });

          return;
        } else {
          let OldData = _.get(skeleton, item.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });

          return;
        }
      } else {
        if (item?.fromComponentLib) {
          handleAddItem(item.type, store, skeletonState, parentToEdit);
        } else if (item?.type !== "kpi") {
          store.dispatch({
            type: "explorer/moveItem",
            payload: {
              globalSkeloton: skeleton,
              parent: dropPath,
              key: item.type,
              dragData: item.dragData,
              dragPath: item.dragPath,
              dragParentPath: item.dragParentPath,
            },
          });
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      if (
        (pages?.length > 1 &&
          pages?.length === 2 &&
          (pages?.[0].gis || pages?.[1].gis)) ||
        pages?.length === 1
      ) {
        if (indexRow === "N") {
          setInsideInner(["maxapps", "page", "row"]);
        } else {
          setInsideInner(["maxapps", "page", "row", String(indexRow)]);
        }
      } else {
        if (indexRow === "N") {
          setInsideInner(["maxapps", "page", String(currentPage), "row"]);
        } else {
          setInsideInner([
            "maxapps",
            "page",
            String(currentPage),
            "row",
            String(indexRow),
          ]);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let ref = useRef();
  drop(ref);

  const finalStyle = {
    ...style,
  };
  return (
    <div style={finalStyle} ref={ref} className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default DropTargetRow;
