import React, { useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { setIn, getIn } from "lodash-redux-immutability";
import { AppContext } from "../../../services/AppContext";
import imxtools from "imxtools";
import { arraysAreEqual } from "../../../../../utils/util";

function DragItemApp({
  accept,
  listApps,
  setListApps,
  index,
  app,
  children,
  component,
  dragData = {},
  dragPath = [],
  hoveredComponent,
  dragParentPath = [],
  style = {},
  fromComponentLib = false,
  className = "",
  key = null,
  onClick = () => {},
}) {
  let store = useStore();
  const {
    setHoveredData,
    setDragedData,
    dragParentHovered,
    setDragParentHovered,
    dragParentDraged,
    setDragParentDraged,
    hoveredIndex,
    setHoveredIndex,
    dragedIndex,
    setDragedIndex,
    typeAction,
    setTypeAction,
    isDragingIn,
    setIsDragingIn,
  } = useContext(AppContext);

  const ref = useRef(null);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer?.skeleton
      : {}
  );

  const moveItemInst = (hoveredItem, dragedItem) => {
    const item = listApps[dragedItem];
    setListApps((prevState) => {
      const newItems = prevState.filter((i, idx) => idx !== dragedItem);
      newItems.splice(hoveredItem, 0, item);
      return [...newItems];
    });
    const newItems = listApps.filter((i, idx) => idx !== dragedItem);
    newItems.splice(hoveredItem, 0, item);
    const dataSorted = newItems;
    let newData = setIn(
      skeletonState,
      [...dragParentPath, component],
      dataSorted
    );

    store.dispatch({
      type: "explorer/dataAdded",
      payload: {
        skeleton: newData,
        readOnly: false,
      },
    });
  };
  const moveItem = (hoveredItem, dragedItem, dragParentHovered) => {
    const item = listApps[dragedItem];

    let newItems = listApps.filter((i, idx) => idx !== dragedItem);
    if (newItems.length > 0) {
      newItems.splice(hoveredItem, 0, item);
    }
    let newData = setIn(
      skeletonState,
      [...dragParentHovered, component],
      newItems
    );
    store.dispatch({
      type: "explorer/dataAdded",
      payload: {
        skeleton: newData,
        readOnly: false,
      },
    });
  };

  const addToNewGroup = (
    dragedIndex,
    hoveredIndex,
    dragParentHovered,
    dragParentDraged,
    dragPath
  ) => {
    if (
      dragParentHovered == null &&
      (hoveredIndex == null || dragedIndex == null)
    ) {
      return;
    }
    let newItems = listApps.filter((i, idx) => idx !== hoveredIndex);
    let newDatatoAdd = getIn(skeletonState, [
      ...dragParentDraged,
      component,
      hoveredIndex,
    ]);

    let newDataGetted = imxtools.toArray(
      getIn(skeletonState, [...dragParentHovered, component])
    );

    let newDataAdd =
      Boolean(newDataGetted) && newDataGetted?.length > 0
        ? [...newDataGetted, newDatatoAdd]
        : [newDatatoAdd];

    let newData = setIn(
      skeletonState,
      [...dragParentDraged, component],
      newItems
    );

    let newDataAdded = setIn(
      newData,
      [...dragParentHovered, component],
      newDataAdd
    );

    store.dispatch({
      type: "explorer/dataAdded",
      payload: {
        skeleton: newDataAdded,
        readOnly: false,
      },
    });

    return;
  };

  const actionDrop = (
    dragedIndex,
    hoveredIndex,
    dragParentHovered,
    dragParentDraged,
    typeAction,
    dragPath
  ) => {
    if (typeAction === "move") {
      moveItem(hoveredIndex, dragedIndex, dragParentHovered);
    } else if (typeAction === "add") {
      addToNewGroup(
        dragedIndex,
        hoveredIndex,
        dragParentHovered,
        dragParentDraged,
        dragPath
      );
    } else {
      return;
    }
    setDragParentDraged(null);
    setDragParentHovered(null);
    setDragedIndex(null);
    setHoveredIndex(null);
    setDragedData(null);
    setTypeAction("");
  };
  const [{ isDragging }, drag, preview] = useDrag({
    type: component,
    item: {
      type: component,
      index,
      dragData,
      dragPath,
      dragParentPath,
      fromComponentLib,
    },

    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),

    end: (item, monitor) => {
      actionDrop(
        dragedIndex,
        hoveredIndex,
        dragParentHovered,
        dragParentDraged,
        typeAction,
        dragPath
      );

      setDragParentDraged(null);
      setDragParentHovered(null);
      setDragedIndex(null);
      setHoveredIndex(null);
      setDragedData(null);
    },
  });

  const [, drop] = useDrop({
    accept: accept,
    hover(item, monitor) {
      let hoveredData = app;
      let dragedData = item.dragData;
      let dragParentHovered = dragParentPath;
      let dragParentDraged = item.dragParentPath;
      let hoveredIndex = item.index;
      let dragedIndex = index;
      // let dragPath = item.dragPath;

      setHoveredData(hoveredData);
      setDragedData(dragedData);
      setDragParentHovered(dragParentHovered);
      setDragParentDraged(dragParentDraged);
      setHoveredIndex(hoveredIndex);
      setDragedIndex(dragedIndex);
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (
        arraysAreEqual(dragParentDraged, dragParentHovered) &&
        hoveredIndex === dragedIndex
      ) {
        return;
      } else {
        if (
          arraysAreEqual(dragParentDraged, dragParentHovered) &&
          hoveredIndex !== dragedIndex
        ) {
          setTypeAction("move");
          if (dragIndex === hoverIndex) {
            return;
          }
          if (
            hoveredComponent === "kpi" &&
            arraysAreEqual(dragParentDraged, dragParentHovered)
          ) {
            if (dragParentHovered[dragParentHovered.length - 1] === "page") {
              const hoveredRect = ref.current.getBoundingClientRect();
              const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
              const mousePosition = monitor.getClientOffset();
              const hoverClientY = mousePosition.y - hoveredRect.top;
              if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
              }
            } else {
              const hoveredRect = ref.current.getBoundingClientRect();
              const hoverMiddleX = (hoveredRect.right - hoveredRect.left) / 2;
              const mousePosition = monitor.getClientOffset();
              const hoverClientX = mousePosition.x - hoveredRect.left;

              if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
              }
            }
          } else {
            const hoveredRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
            const mousePosition = monitor.getClientOffset();
            const hoverClientY = mousePosition.y - hoveredRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return;
            }
          }

          moveItemInst(dragIndex, hoverIndex);
          item.index = hoverIndex;

          return;
        } else if (!arraysAreEqual(dragParentDraged, dragParentHovered)) {
          setTypeAction("add");
        }
      }
    },
  });

  drag(drop(ref));

  preview(<div>{children}</div>);

  return (
    <div
      key={key}
      ref={ref}
      className={`${className} ${isDragging ? "dragging" : ""}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default DragItemApp;
