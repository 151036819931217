import React, { useContext, useEffect, useRef, useState } from "react";
import { useStore, useSelector } from "react-redux";
import { getIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import Tab from "../../Tab";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import DropTargetBody from "../../DropTargetBody";
import DragPage from "../../../../../ComponentsLibrary/DragPage";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import { useDeviceTheme } from "../../../../../../../../DeviceTheme/DeviceThemeContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import { AppContext } from "../../../../../../services/AppContext";
import Module from "../../Module";

const TimerDetail = () => {
  const [data, setData] = useState();
  const [parentData, setParentData] = useState();
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState({});
  const [numtabs, setNumTabs] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [path, setPath] = useState([]);
  let store = useStore();
  const scrollRef = useRef(null);
  const { theme, themes } = useDeviceTheme();
  const { setBodyDetail } = useContext(AppContext);
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);
  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit || []
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  let navigationPath = useSelector(
    (state) => state?.entities?.deviceview?.navigationPath
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  useEffect(() => {
    const { path } = navigationPath[navigationPath.length - 1]?.props;
    const newPath = path.slice(0, -1);
    setParentData(getIn(skeleton, newPath));
  }, [navigationPath?.length, skeleton]);
  useEffect(() => {
    const { path } = navigationPath[navigationPath.length - 1]?.props;
    const data = getIn(skeleton, path);
    setData(data);

    setPath(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, skeleton]);

  const handleNavigateBack = (e) => {
    e.stopPropagation();
    store.dispatch({
      type: "deviceview/navigateBack",
    });
    const { path, data } = navigationPath[navigationPath?.length - 2]?.props;
    handleComponentProp(e, null, data, path, store, path?.[path?.length - 1]);
  };

  ///

  ////
  return (
    <div className="TimerDetail">
      <div
        className="headerTimer"
        style={{
          height: readOnly && "32px",
          maxHeight: readOnly && "32px",
          minHeight: readOnly && "32px",
          flexDirection:
            language?.toLowerCase() === "ar" ? "row-reverse" : "row",
        }}
        onClick={(e) => {
          handleComponentProp(e, null, data, path, store, "timer");
        }}
      >
        <div
          className="goBack"
          style={{ width: readOnly && "32px" }}
          onClick={(e) => {
            handleNavigateBack(e);
            setBodyDetail(false);
          }}
        >
          <Icon
            icon={
              language?.toLowerCase() === "ar"
                ? "chevron-right"
                : "chevron-left"
            }
            className="chevron-left-Timer"
            size={20}
          />
        </div>

        <DropTargetBody
          dragParent={path}
          className={`drop-timer-zone ${
            isSelectedComponent(readOnly, path, currentPath)
              ? "selectedComponent"
              : "unSelectedComponent hovered"
          }`}
          onClick={(e) => {
            handleComponentProp(e, null, data, path, store, "timer");
            setBodyDetail(false);
          }}
        >
          <div className="timerHeader">Active Timer</div>
        </DropTargetBody>
      </div>
      <div
        className="timer-content"
        style={{
          height: readOnly && "calc(100% - 32px)",
          maxHeight: readOnly && "calc(100% - 32px)",
          minHeight: readOnly && "calc(100% - 32px)",
        }}
        onClick={(e) => {
          handleComponentProp(e, null, data, path, store, "timer") &&
            setBodyDetail(false);
        }}
      >
        <Module
          Timerdata={data}
          path={path}
          numModule={data?.attribute?.length}
          readOnly={readOnly}
          timer={true}
        />
      </div>
    </div>
  );
};

export default TimerDetail;
