import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { useSelector, useStore } from "react-redux";
import "./index.scss";
import imxtools from "imxtools";
import { navigateTo } from "../../services/navigationMiddleware";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { ComponentsLibraries } from "../../../../../services/common";
import DropTargetModule from "../../services/DropTargetModule";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import DragItemApp from "../../../../ComponentsLibrary/DragItemApp";
import { AppContext } from "../../../../../services/AppContext";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import DropTargetBody from "../DropTargetBody";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import Attribute from "../Attribute";

function Module({
  data = {},
  Timerdata = {},
  indexModule = "",
  path = [],
  numModule,
  readOnly = true,
  currentPage,
  pages,
  setOpenModuleMatrix = () => {},
  setModuleData = () => {},
  timer = false,
}) {
  const [openModule, setOpenModule] = useState(true);
  const [hoverStates, setHoverStates] = useState([]);
  const [listApps, setListApps] = useState([]);
  const [theItem, setTheItem] = useState(false);
  const [hoverAtt, setHoverAtt] = useState(false);
  const [moduleContainerHovered, setModuleContainerHovered] = useState(false);
  let store = useStore();
  const [dataAttributes, setDataAttributes] = useState([]);
  const { dragParentHovered, isDragingIn, setIsDragingIn, setBodyNotif } =
    useContext(AppContext);

  const handleMouseEnter = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  };

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  let language = useSelector((state) => state?.entities?.deviceview?.language);

  let view = useSelector(
    (state) => state?.entities?.deviceview?.settings?.view || "row"
  );

  const handleNavigateTo = (
    event,
    indexApp,
    indexModule,
    app,
    data,
    numApp
  ) => {
    event.stopPropagation();
    const modulePath =
      numModule === 1
        ? [...path, "module"]
        : [...path, "module", indexModule.toString()];
    const newPath =
      numApp === 1
        ? [...modulePath, app]
        : [...modulePath, app, indexApp.toString()];
    navigateTo(store, "List", { data, path: newPath });
  };

  const handleOpenModule = (event, open = false) => {
    if (open) {
      if (view?.toLowerCase() === "row") {
        setOpenModule(!openModule);
      } else {
        setModuleData({
          label: data?.label,
          numModule: numModule,
          path: path ?? [],
          apps: listApps,
          indexModule: indexModule,
          onMouseEnter: () => setModuleContainerHovered(true),
          onMouseLeave: () => setModuleContainerHovered(false),
        });
        setOpenModuleMatrix(true);
      }
    }
    !timer &&
      handleComponentProp(
        event,
        numModule === 1 ? null : indexModule?.toString(),
        data,
        path,
        store,
        "module"
      );
  };

  useEffect(() => {
    setModuleData({
      label: data?.label,
      numModule: numModule,
      path: path ?? [],
      apps: listApps,
      indexModule: indexModule,
      onMouseEnter: () => setModuleContainerHovered(true),
      onMouseLeave: () => setModuleContainerHovered(false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listApps]);

  useEffect(() => {
    if (!timer) {
      if (numModule === 1) {
        if (arraysAreEqual(dragParentHovered, [...path, "module"])) {
          setOpenModule(true);
        }
      } else if (
        arraysAreEqual(dragParentHovered, [
          ...path,
          "module",
          indexModule?.toString(),
        ])
      ) {
        setOpenModule(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragParentHovered]);

  useEffect(() => {
    !timer
      ? setListApps(imxtools?.toArray(data?.app))
      : setDataAttributes(imxtools?.toArray(Timerdata?.attribute));
  }, [data, Timerdata]);
  useEffect(
    (e) => {
      listApps?.length > 0 &&
        !openModule &&
        !readOnly &&
        !timer &&
        handleOpenModule(e, true);
    },
    [listApps]
  );
  useEffect(() => {
    if (!readOnly) {
      setOpenModule(false);
    }
  }, [readOnly]);
  //
  const dropPath =
    numModule === 1
      ? [...path, "module"]
      : [...path, "module", indexModule?.toString()];
  useEffect(() => {
    if (listApps.length === 0) {
      setOpenModule(true);
    }
  }, [listApps]);

  return (
    <DropTargetModule
      className={`${
        view === "row" ? "DropTargetModule" : "DropTargetModule-matrix"
      } ${
        !timer &&
        isSelectedComponent(readOnly, dropPath, currentPath) &&
        "selectedComponent"
      } ${
        listApps?.length === 0 &&
        (view === "row"
          ? "DropTargetModule-empty"
          : "DropTargetModule-empty-matrix")
      } `}
      dropPath={dropPath}
      accept={Object.keys(ComponentsLibraries?.module)}
      dropTargetName={"module"}
      indexModule={indexModule}
      currentPage={currentPage}
      pages={pages}
      onMouseEnter={() => setModuleContainerHovered(true)}
      onMouseLeave={() => setModuleContainerHovered(false)}
    >
      <div
        className={`${view === "row" ? "module-card" : "module-card-matrix"} ${
          openModule && "openModule"
        } ${
          isSelectedComponent(readOnly, dropPath, currentPath)
            ? !openModule
              ? "selectedCloseModule"
              : "selectedOpenModule"
            : ""
        }`}
        onClick={(e) => {
          timer
            ? handleComponentProp(e, null, Timerdata, path, store, "timer")
            : handleOpenModule(e);

          setBodyNotif(false);
        }}
        onDoubleClick={(e) => handleOpenModule(e, true)}
        onMouseEnter={() => handleMouseEnter(indexModule)}
        onMouseLeave={() => handleMouseLeave(indexModule)}
        style={{
          borderRadius: timer && "9px",
          background: timer && "var(--timerBG)",
        }}
      >
        <div
          className={`app-icon ${
            openModule
              ? timer
                ? "openTimer"
                : "open"
              : timer
              ? "closeTimer"
              : "close"
          }`}
        >
          <Icon
            icon={timer ? "appgen_solid" : data?.icon ? data?.icon : "notoper"}
            size={view === "row" ? 20 : 45}
            color={view !== "row" ? "white" : timer ? "var(--timerText)" : ""}
          />
        </div>
        <div
          className={`app-title ${
            openModule
              ? timer
                ? "openTimer"
                : "open"
              : timer
              ? "closeTimer"
              : "close"
          }`}
        >
          <div className="title">
            {data?.label ? getLabelByLanguage(language, data) : "Add label"}
          </div>
        </div>
        {view === "row" && (
          <div
            className={`icon-chevron ${
              openModule
                ? timer
                  ? "openTimer"
                  : "open"
                : timer
                ? "closeTimer"
                : "close"
            }`}
          >
            <Icon icon={openModule ? "chevron-up" : "chevron-down"} size={15} />
          </div>
        )}
      </div>
      {view?.toLowerCase() === "row" && (
        <div
          className={`apps-module ${
            readOnly ? " " : timer ? !hoverAtt && "hovered" : "hovered"
          } ${
            isSelectedComponent(readOnly, dropPath, currentPath) &&
            "selectedAppsModule"
          }`}
        >
          {!timer ? (
            <>
              {openModule &&
                (listApps?.length > 0
                  ? listApps?.map((app, indexApp) => {
                      const lastApp =
                        imxtools?.toArray(data?.app)?.length - 1 === indexApp;
                      let numApp = imxtools?.toArray(
                        timer ? data?.attribute : data?.app ?? []
                      )?.length;
                      const dragPath =
                        numModule === 1
                          ? numApp === 1
                            ? [...path, "module", "app"]
                            : [...path, "module", "app", indexApp?.toString()]
                          : numApp === 1
                          ? [...path, "module", indexModule?.toString(), "app"]
                          : [
                              ...path,
                              "module",
                              indexModule?.toString(),
                              "app",
                              indexApp?.toString(),
                            ];
                      return (
                        <DragItemApp
                          accept={["app"]}
                          className={`drag-apps-module ${
                            lastApp && "lastAppModule"
                          } ${
                            isSelectedComponent(
                              readOnly,
                              dragPath,
                              currentPath
                            ) && "selectedComponent"
                          }`}
                          key={indexApp}
                          dataSorted={listApps}
                          sort={true}
                          app={app}
                          index={indexApp}
                          theItem={theItem}
                          listApps={listApps}
                          setListApps={setListApps}
                          setTheItem={setTheItem}
                          hoveredComponent={"module"}
                          component={"app"}
                          dragData={app}
                          dragPath={dragPath}
                          dragParentPath={
                            numModule === 1
                              ? [...path, "module"]
                              : [...path, "module", indexModule?.toString()]
                          }
                        >
                          <div
                            className="app-module"
                            onClick={(e) => {
                              handleComponentProp(
                                e,
                                numApp === 1 ? null : indexApp?.toString(),
                                app,
                                numModule === 1
                                  ? [...path, "module"]
                                  : [
                                      ...path,
                                      "module",
                                      indexModule?.toString(),
                                    ],
                                store,
                                "app"
                              );
                              setBodyNotif(false);
                            }}
                            onDoubleClick={(event) =>
                              handleNavigateTo(
                                event,
                                indexApp,
                                indexModule,
                                "app",
                                app,
                                numApp
                              )
                            }
                          >
                            <div className="icon-app-module">
                              <Icon
                                className="notoperr"
                                icon={app?.icon ? app?.icon : "notoper"}
                                size={20}
                              />
                            </div>
                            <div className="title-app-module">
                              <div className="title">
                                {app?.label
                                  ? getLabelByLanguage(language, app)
                                  : `Add label`}
                              </div>
                            </div>
                          </div>
                        </DragItemApp>
                      );
                    })
                  : !readOnly && (
                      <div className="drop-apps-empty-module">
                        <div className="drag-app-empty-module">
                          {readOnly ? "No Apps here" : "Drop Apps here"}
                        </div>
                      </div>
                    ))}
            </>
          ) : (
            <DropTargetBody
              accept={["attribute"]}
              dragParent={path}
              className="drop-target-timer"
              style={{
                display: !openModule && "none",
                border: dataAttributes.length <= 0 && "none",
                alignItems: dataAttributes.length > 0 && "unset",
              }}
            >
              {dataAttributes.length > 0 ? (
                <>
                  {imxtools.toArray(Timerdata?.attribute)?.map((att, index) => {
                    let numAtt = imxtools.toArray(Timerdata?.attribute)?.length;

                    return (
                      <DragItemAttribute
                        index={index}
                        component={"attribute"}
                        classname="dragAttributeContainer"
                        dragParentPath={path}
                        dragData={att}
                        dataAttributes={dataAttributes}
                        setDataAttributes={setDataAttributes}
                        setIsDraging={setIsDragingIn}
                      >
                        <Attribute
                          data={att}
                          numAttributes={numAtt}
                          readOnly={readOnly}
                          index={numAtt > 1 ? index : null}
                          path={path}
                          clicked={arraysAreEqual(
                            numAtt === 1
                              ? [...path, "attribute"]
                              : [...path, "attribute", index?.toString()],
                            currentPath
                          )}
                          setHoverAtt={setHoverAtt}
                        />
                      </DragItemAttribute>
                    );
                  })}
                </>
              ) : (
                <div className="empty-attribute">
                  {readOnly ? "No Attribute(s) here" : "Drop Attribute(s) here"}
                </div>
              )}
            </DropTargetBody>
          )}
        </div>
      )}
    </DropTargetModule>
  );
}

export default Module;
