import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import "./index.scss";
import DropTargetBody from "../DropTargetBody";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import GisLayersNew from "./GisLayersNew";
import Form from "../Form";
import imxtools from "imxtools";
import PinMap from "./../../../../../../../assets/PinMap.png";
import Pullup from "./../../../Pullup/index";
import Modal from "../Modal";
import { getIn } from "lodash-redux-immutability";
import { Marker } from "@react-google-maps/api";
import FilterForm from "../FilterForm";
import MarkerDrop from "../MarkerDrop";
import HeaderGis from "../HeaderGis";

function GIS({ google, path = [], data = {} }) {
  let store = useStore();
  const [mapTypeView, setMapTypeView] = useState("terrain");
  const [displayMapLabels, setDisplayMapLabels] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [targetForm, setTargetForm] = useState("");
  const [pathOfList, setPathOfList] = useState([]);
  const [targetFormIndex, setTargetFormIndex] = useState(null);
  const [formHform, setFormHform] = useState(null);
  const [openPullup, setOpenPullup] = useState(false);

  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  const [openForm, setOpenForm] = useState(false);
  const [openFilterForm, setOpenFilterForm] = useState(false);
  const [openModalMarker, setOpenModalMarker] = useState(false);
  const [currentMarkerPath, setCurrentMarkerPath] = useState([]);
  const [displayPullup, setDisplayPullup] = useState(false);
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  let formsData = imxtools.toArray(
    getIn(skeletonState, [...pathOfList, "form"]) || []
  );
  let hformsData = imxtools.toArray(
    getIn(skeletonState, [...pathOfList, "hform"]) || []
  );
  useEffect(() => {
    if (mapTypeView === "street") {
      setMapTypeView("street");
    } else {
      setMapTypeView("terrain");
    }
  }, [mapTypeView, setMapTypeView]);

  const handleMapTypeChange = (newMapType) => {
    setMapTypeView(newMapType);
    setMapKey(mapKey + 1);
  };

  function handleComp(data, newPath, component) {
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  }

  const handleOpenMarker = (e, pin) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event && window.event.stopPropagation) {
      window.event.stopPropagation();
    }
    if (pin?.hasOwnProperty("marker") && pin?.marker !== undefined) {
      setOpenModalMarker(!openModalMarker);
    }
  };

  let choosedList = getIn(skeletonState, [...pathOfList, "marker"]);

  return (
    <>
      <DropTargetBody
        accept={[
          "list",
          "fab",
          "whereclause",
          "marker",
          "toolbar",
          "labortracking",
          "pullup",
          "pin",
        ]}
        dragParent={parentToEdit}
        className="map"
      >
        {choosedList?.pullup && displayPullup ? (
          <HeaderGis
            setOpenModalMarker={setOpenModalMarker}
            setDisplayPullup={setDisplayPullup}
            setOpenPullup={setOpenPullup}
          />
        ) : (
          <></>
        )}

        <div
          className={"map"}
          style={{ borderRadius: "36px" }}
          onClick={(e) =>
            handleComponentProp(e, null, data, path, store, "gis")
          }
        >
          <Map
            key={mapKey}
            google={window.google}
            className="map"
            zoom={14}
            disableDefaultUI={true}
            initialCenter={{ lat: 37.7749, lng: -122.4194 }}
            mapType={mapTypeView === "street" ? "street" : "terrain"}
            styles={[
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
            ]}
          >
            {imxtools
              .toArray(data?.list)
              ?.filter((obj) => obj.hasOwnProperty("whereclause"))
              ?.map((pin, index) => {
                let numList = imxtools
                  .toArray(data?.list)
                  ?.filter((obj) => obj.hasOwnProperty("whereclause"))?.length;
                return (
                  <Marker
                    onClick={(e) => {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      } else if (window.event && window.event.stopPropagation) {
                        window.event.stopPropagation();
                      }
                      handleComp(
                        pin,
                        numList === 1
                          ? [...path, "list"]
                          : [...path, "list", index?.toString()],
                        "list"
                      );
                      setCurrentMarkerPath(
                        numList === 1
                          ? [...path, "list"]
                          : [...path, "list", index?.toString()]
                      );

                      setPathOfList(
                        numList === 1
                          ? [...path, "list"]
                          : [...path, "list", index?.toString()]
                      );
                    }}
                    onDblClick={(e) => {
                      handleOpenMarker(e, pin);
                      handleComp(
                        pin,
                        numList === 1
                          ? [...path, "list"]
                          : [...path, "list", index?.toString()],
                        "list"
                      );
                    }}
                    key={index}
                    position={{
                      lat: 37.7749 + index * 0.01,
                      lng: -122.4194 + index * 0.01,
                    }}
                    icon={{
                      url: PinMap,
                      scaledSize: new window.google.maps.Size(22, 22),
                    }}
                  />
                );
              })}
          </Map>
          <GisLayersNew
            data={data}
            path={path}
            setMapTypeView={setMapTypeView}
            mapTypeView={mapTypeView}
            displayMapLabels={displayMapLabels}
            setDisplayMapLabels={setDisplayMapLabels}
            handleMapTypeChange={handleMapTypeChange}
            openForm={openForm}
            setOpenForm={setOpenForm}
            targetForm={targetForm}
            setTargetForm={setTargetForm}
            pathOfList={pathOfList}
            setPathOfList={setPathOfList}
            targetFormIndex={targetFormIndex}
            setTargetFormIndex={setTargetFormIndex}
            formHform={formHform}
            setFormHform={setFormHform}
            setOpenFilterForm={setOpenFilterForm}
            openPullup={openPullup}
            setCurrentMarkerPath={setCurrentMarkerPath}
            currentMarkerData={choosedList}
            displayPullup={displayPullup}
          />
        </div>
      </DropTargetBody>
      {choosedList?.hasOwnProperty("pullup") && displayPullup ? (
        <Pullup
          openPullup={openPullup}
          setOpenPullup={setOpenPullup}
          data={choosedList}
          path={[...pathOfList, "marker"]}
        />
      ) : (
        <></>
      )}

      <Modal openModal={openFilterForm} tab={true} isForm={true}>
        <FilterForm
          setOpenFilterForm={setOpenFilterForm}
          data={data}
          path={path}
        />
      </Modal>
      {openModalMarker ? (
        <MarkerDrop
          openModalMarker={openModalMarker}
          currentMarkerData={choosedList}
          currentMarkerPath={[...currentMarkerPath, "marker"]}
          path={currentMarkerPath}
          data={data}
          openPullup={openPullup}
          setOpenPullup={setOpenPullup}
          setDisplayPullup={setDisplayPullup}
          setOpenModalMarker={setOpenModalMarker}
          setPathOfList={setPathOfList}
        />
      ) : (
        <></>
      )}
      <Modal openModal={openForm} tab={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData?.length === 1
                ? [...pathOfList, formHform]
                : [...pathOfList, formHform, targetFormIndex]
              : hformsData?.length === 1
              ? [...pathOfList, formHform]
              : [...pathOfList, formHform, targetFormIndex]
          }
          parentPath={pathOfList}
          component={formHform}
          setOpenForm={setOpenForm}
          readOnly={readOnly}
          inGis={true}
          hform={formHform === "hform"}
        />
      </Modal>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDQdZ3NJ_ScT4WsJG00f1drRUksu062db4",
})(GIS);
