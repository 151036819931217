import React, { useState } from "react";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import generateRandomData from "../../services/randomDataGenerator";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import imxtools from "imxtools";
import Modal from "../Modal";
import Lookup from "../Lookup";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Field({ data = {}, path = [], numFields, index, readOnly = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const [targetLookup, setTargetLookup] = useState({});
  const [targetLookupIndex, setTargetLookupIndex] = useState(0);
  let store = useStore();
  let edit = useSelector((state) => state?.entities?.editor);

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  let lookups = useSelector((state) =>
    state?.entities?.deviceview?.lookups
      ? state?.entities?.deviceview?.lookups
      : []
  );

  const renderExpendedOptions = (value) => {
    const expendedOptions = [];
    for (let i = 0; i < Math.floor(Math.random() * 7) + 2; i++) {
      expendedOptions.push(<div className="expendedOptions">{value}</div>);
    }
    return expendedOptions;
  };

  const handleLookup = (ref) => {
    const lookupIndex = imxtools
      ?.toArray(lookups)
      ?.findIndex((l) => l?.reference?.toLowerCase() === ref?.toLowerCase());
    if (lookupIndex === undefined) {
      return;
    }
    setTargetLookup(lookups[lookupIndex]);
    setTargetLookupIndex(lookupIndex?.toString());
  };

  return (
    <>
      {data?.hidden?.toLowerCase() === "true" ? (
        !readOnly && (
          <div
            className={
              numFields > 1
                ? arraysAreEqual(
                    [...path, "field", index?.toString()],
                    edit.parentToEdit
                  ) && !readOnly
                  ? `field-container ${"selectedComponent"}`
                  : `field-container`
                : `field-container ${
                    isSelectedComponent(
                      readOnly,
                      [...path, "field"],
                      edit.parentToEdit,
                      "field",
                      index?.toString()
                    ) && "selectedComponent"
                  }`
            }
            style={{
              alignItems:
                language?.toLowerCase() === "ar" ? "flex-end" : "flex-start",
            }}
            onClick={(event) => {
              handleComponentProp(
                event,
                numFields <= 1 ? null : index?.toString(),
                data,
                path,
                store,
                "field"
              );
            }}
          >
            {!data?.lookup && !data?.displaymode ? (
              data?.inputmode?.toLowerCase()?.includes("readonly") ? (
                <>
                  <div className="field-label">
                    {data?.label ? data?.label : "--"}
                  </div>
                  <div className="field-value">
                    {data?.type ? generateRandomData(data?.type) : "--"}
                  </div>
                </>
              ) : (
                <textarea
                  defaultValue={data?.init ? data?.init : ""}
                  rows={data?.multiline ? data?.multiline : 1}
                  placeholder={
                    data?.label ? getLabelByLanguage(language, data) : "--"
                  }
                  className="simpleInput"
                  style={{
                    textAlign:
                      language?.toLowerCase() === "ar" ? "end" : "start",
                  }}
                />
              )
            ) : (
              <></>
            )}
            {data?.lookup && !data?.displayMode ? (
              data?.inputmode?.toLowerCase()?.includes("readonly") ? (
                <div className="field-lookup">
                  <div className="field-right">
                    <div className="field-label">
                      {data?.label ? data?.label : "--"}
                    </div>
                    <div className="field-value">
                      {data?.type ? generateRandomData(data?.type) : "--"}
                    </div>
                  </div>
                  <div
                    className="field-left"
                    onDoubleClick={() => {
                      handleLookup(data?.lookup);
                      setIsOpen(true);
                    }}
                  >
                    <Icon icon="search" size={18} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="field-label">
                    {data?.label ? data?.label : "--"}
                  </div>
                  not readonly
                </>
              )
            ) : (
              <></>
            )}
            {data?.lookup && data?.displayMode ? (
              data?.displayMode?.toLowerCase() === "picker" ? (
                <div className="field-lookup">
                  <div className="field-right">
                    <div className="field-label">
                      {data?.label ? data?.label : "--"}
                    </div>
                    <div className="field-value">
                      {data?.type ? generateRandomData(data?.type) : "--"}
                    </div>
                  </div>
                  <div className="field-left">
                    <Icon icon="chevron-down" size={16} />
                  </div>
                </div>
              ) : data?.displayMode?.toLowerCase() === "expendedoptions" ? (
                <div className="field-expendedoptions">
                  <div className="field-label">
                    {data?.label ? data?.label : "--"}
                  </div>
                  <div className="expendedoptions"></div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        )
      ) : (
        <div
          className={
            numFields > 1
              ? arraysAreEqual(
                  [...path, "field", index?.toString()],
                  edit.parentToEdit
                ) && !readOnly
                ? `field-container ${"selectedComponent"}`
                : `field-container`
              : `field-container ${
                  isSelectedComponent(
                    readOnly,
                    [...path, "field"],
                    edit.parentToEdit,
                    "field",
                    index?.toString()
                  ) && "selectedComponent"
                }`
          }
          style={{
            alignItems:
              language?.toLowerCase() === "ar" ? "flex-end" : "flex-start",

            height:
              data?.inputmode?.toLowerCase()?.includes("readonly") && "100%",
          }}
          onClick={(event) =>
            handleComponentProp(
              event,
              numFields <= 1 ? null : index?.toString(),
              data,
              path,
              store,
              "field"
            )
          }
        >
          {!data?.lookup &&
            !data?.displaymode &&
            (data?.inputmode?.toLowerCase()?.includes("readonly") ? (
              <div className="field-label">
                {data?.label ? getLabelByLanguage(language, data) : "--"}
              </div>
            ) : (
              <textarea
                defaultValue={data?.init ? data?.init : ""}
                rows={data?.multiline ? data?.multiline : 1}
                placeholder={
                  data?.label ? getLabelByLanguage(language, data) : "--"
                }
                className="simpleInput"
                style={{
                  textAlign: language?.toLowerCase() === "ar" ? "end" : "start",
                }}
              />
            ))}

          {data?.lookup &&
            !data?.displayMode &&
            (data?.inputmode?.toLowerCase()?.includes("readonly") ? (
              <div
                className="field-lookup"
                style={{
                  textAlign: language?.toLowerCase() === "ar" && "right",
                  flexDirection:
                    language?.toLowerCase() === "ar" ? "row-reverse" : "row",
                }}
              >
                <div className="field-right">
                  <div className="field-label">
                    {data?.label ? getLabelByLanguage(language, data) : "--"}
                  </div>
                  <div className="field-value">
                    {data?.type ? generateRandomData(data?.type) : "--"}
                  </div>
                </div>
                <div
                  className="field-left"
                  onDoubleClick={() => {
                    handleLookup(data?.lookup);
                    setIsOpen(true);
                  }}
                >
                  <Icon icon="search" size={18} className="SearchIcon" />
                </div>
              </div>
            ) : (
              <div
                className="filed-lookup-not-readOnly"
                style={{
                  textAlign: language?.toLowerCase() === "ar" && "right",
                  flexDirection:
                    language?.toLowerCase() === "ar" ? "row-reverse" : "row",
                }}
              >
                <div
                  className="field-lookup-left"
                  style={{
                    alignItems:
                      language?.toLowerCase() === "ar"
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <div className="field-lookup-label">
                    {data?.label ? getLabelByLanguage(language, data) : "--"}
                  </div>
                  <div className="field-lookup-value">
                    {data?.type ? generateRandomData(data?.type) : "--"}
                  </div>
                </div>
                <div
                  className="field-lookup-right"
                  onDoubleClick={() => {
                    handleLookup(data?.lookup);
                    setIsOpen(true);
                  }}
                >
                  <Icon icon="search" size={20} />
                </div>
              </div>
            ))}

          {data?.lookup &&
            data?.displayMode &&
            (data?.displayMode?.toLowerCase() === "picker" ? (
              <>
                {!data?.inputmode?.toLowerCase()?.includes("readonly") ? (
                  <div className="filed-lookupPicker-not-readOnly">
                    <div className="field-lookup-left">
                      {data?.label ? data?.label : "--"}
                    </div>
                    <div className="field-lookup-right">
                      <Icon icon="chevron-down" size={16} />
                    </div>
                  </div>
                ) : (
                  <div className="field-lookup">
                    <div className="field-right">
                      <div className="field-label">
                        {data?.label ? data?.label : "--"}
                      </div>
                      <div className="field-value">
                        {data?.type ? generateRandomData(data?.type) : "--"}
                      </div>
                    </div>
                    <div className="field-left">
                      <Icon icon="chevron-down" size={16} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              data?.displayMode?.toLowerCase() === "expendedoptions" && (
                <div className="field-expendedoptions">
                  {/* <div className="field-label">
                    {data?.label ? data?.label : "--"}
                  </div> */}
                  <div className="values-expendedoptions">
                    {renderExpendedOptions(
                      data?.type ? generateRandomData(data?.type) : "--"
                    )}
                  </div>
                </div>
              )
            ))}
        </div>
      )}
      <Modal openModal={isOpen} field={true}>
        <Lookup
          data={targetLookup}
          setIsOpen={setIsOpen}
          path={["maxapps", "lookups", "lookup", targetLookupIndex]}
        />
      </Modal>
    </>
  );
}

export default Field;
