import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { useStore, useSelector } from "react-redux";
import useToasts from "../../../../../../components/CustomToast/useToasts";
import { MoreVert } from "@mui/icons-material";
import "./index.scss";
import handleReadContentVersion from "../../../../services/helpers/handleReadContentVersion";
import editXmlAlias from "../../../../services/editXmlAlias";
import handleEditContentVersion from "../../../../services/helpers/handleEditContentVersion";
import deleteVersion from "../../../../services/deleteVersion";
import CustomModal from "../../../../../../components/CustomModal";
import Dropdown from "../../../../../../components/Dropdown";
import HandleEditMode from "../../../CompareFiles/services/handleEditMode";
import transformForXml from "../../../../services/helpers/transformForXml";
import handlePublish from "../../../AppBar/services/handlePublish";
import { useTheme } from "../../../../../../theme/themeContext";
import Icon from "../../../../../../components/CustomIcon/Icon";
import getFileContent from "../../../../services/getFileContent";
import RevisionToCompare from "../Services/revisionTCompare";
import getFileXmlContent from "../../../CompareFiles/services/getFileXmlContent";
import { useLocation } from "react-router-dom";
import ExportRevisionItem from "../Services/exportRevisionitem";

const LastPublishedRevision = ({
  data,
  versionsData,
  showVersion = "true",
  selectedData = {},
  enableBackground,
  setEnableBackground = () => {},
  compareXML,
  setCompareXML = () => {},
  setRevision = () => {},
  setIsTransformed = () => {},
  revision,
  Bordered,
  setBorder = () => {},
  setShowDraftsList = () => {},
}) => {
  let store = useStore();
  let location = useLocation();
  const { showRight, leftFileName, showLeft } = useSelector((state) => ({
    showRight: state?.entities?.compareFiles?.rightCode?.active,
    leftFileName: state?.entities?.compareFiles?.leftCode?.filename || "",
    showLeft: state?.entities?.compareFiles?.leftCode?.active,
  }));
  const { addToast } = useToasts();
  const [newFileName, setNewFileName] = useState(data?.xmlAlias);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const isOn = JSON.parse(localStorage.getItem("isUp"));
  const iconClicked = JSON.parse(localStorage.getItem("iconClicked"));
  const [showEditPopup, setShowEditPopup] = useState(false);
  const { themeMode, themeColors } = useTheme();
  const readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const draftPresence = store.getState()?.entities?.explorer?.draftDisplay;
  let selected = useSelector((state) =>
    state?.entities?.revision?.itemSelected
      ? state?.entities?.revision.itemSelected
      : {}
  );

  const { dataVersion, edit, skeleton, typeFile } = useSelector((state) => ({
    dataVersion: state?.entities?.explorer?.skeleton || {},
    edit: state?.entities?.editor || {},
    skeleton: state?.entities?.explorer?.skeleton || {},
    typeFile: state?.entities?.revision?.revisionSelected?.typeFile,
  }));
  const deleteConfirmation = {
    confirmQuestion: "Are you sure you want to delete this version?",
    versionName: ` ${data?.xmlAlias ?? "My Alias"}`,
    versionDate: data?.release,
  };

  const handleEditVersion = async () => {
    try {
      setShowEditPopup(false);
      await handleEditContentVersion(
        addToast,
        store,
        selectedData?.filename,
        selectedData?.maxOrder,
        selectedData
      );

      if (showRight) {
        store.dispatch({
          type: "compareFiles/updateCompareState",
          payload: {
            compare: true,
          },
        });
      }
      HandleEditMode(
        store,
        showLeft,
        showRight,
        leftFileName,
        isOn,
        iconClicked
      );
    } catch (error) {
      console.error("Error during publish or edit version process:", error);
      addToast("An error occurred during the process.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleReadContent = async () => {
    handleReadContentVersion(addToast, store, data?.filename, data, readOnly);
    if (!Bordered) {
      setBorder(true);
    }
  };

  const handleEditAlias = async () => {
    try {
      setShowRenamePopup(false);
      await editXmlAlias(
        store,
        data?.filename,
        newFileName,
        addToast,
        selectedData,
        selected
      );
    } catch (error) {
      addToast("Server is unreachable!", "error");
    }
  };

  const handleDelete = async () => {
    setShowDeletePopup(false);

    await deleteVersion(
      store,
      selectedData.filename,
      addToast,
      selectedData.typeFile,
      selectedData,
      setBorder
    );
  };

  const buttonRef = useRef(null);
  async function changeHandler(selectedFile) {
    store.dispatch({
      type: "revision/setSelectedItem",
      payload: {
        obj: {},
      },
    });
    store.dispatch({
      type: "revision/setSelectedRevision",
      payload: { data: {} },
    });

    try {
      const file = selectedFile.filename;
      const res = await getFileContent(store, file, readOnly, addToast);
      const skeleton = store.getState().entities.explorer.skeleton;

      await handlePublish(
        transformForXml(skeleton),
        newFileName,
        store,
        "draft",
        addToast,
        false
      );
      const versionsList = store.getState().entities.revision?.list;

      const SelectedVersion = versionsList[0];
      store.dispatch({
        type: "revision/setSelectedRevision",
        payload: {
          data: SelectedVersion,
        },
      });
      setEnableBackground(true);
      store.dispatch({
        type: "explorer/displayDraft",
        payload: { draftDisplay: true },
      });
    } catch (error) {
      addToast(error.message, "error");
    }
  }

  const ExportFile = async (item) => {
    const file = item.filename;

    if (file && file !== "") {
      await ExportRevisionItem(file, addToast, location, item.xmlAlias);
    }
  };

  const compareRevision = async (item) => {
    const file = item.filename;
    const finalXML = await getFileXmlContent(file);
    if (!showLeft && !compareXML) {
      setCompareXML(!compareXML);
      RevisionToCompare(store, item, addToast, finalXML, showLeft);
    } else {
      RevisionToCompare(store, item, addToast, finalXML, showLeft);
    }

    if (showLeft && revision) {
      setRevision(false);
      setIsTransformed(false);
    }
  };

  const DropdownOptions = [
    {
      label: "Edit",
      action: () => {
        setShowEditPopup(true);
        setBorder(false);
      },
      active: true,
    },

    {
      label: "Export",
      active: true,
      action: () => ExportFile(selectedData),
    },
    {
      label: "Compare Files",
      active: true,
      action: () => compareRevision(selectedData),
    },
    {
      label: "Rename",
      action: () => setShowRenamePopup(true),
      active: true,
    },
    {
      label: "Delete",
      action: () => setShowDeletePopup(true),
      active: true,
    },
  ];

  return (
    <>
      <div
        className={`publishedItem ${Bordered ? "selected" : ""}`}
        ref={buttonRef}
      >
        <div
          className="published-content"
          onClick={draftPresence ? null : handleReadContent}
        >
          <div className="published-section">
            <div className="published-state">Published</div>
            <div className="published-InProd">(In prod)</div>
          </div>
          <div className="published-name">
            {/* {showVersion === "true" ? <>V{data?.version} - </> : null} */}
            {data?.xmlAlias}
          </div>
          <div className="release-datetime">
            {data?.uploadDate
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                }).format(new Date(data.uploadDate))
              : null}
          </div>
        </div>
        <div className="actions-btn">
          {!draftPresence && (
            <Dropdown
              options={DropdownOptions}
              activeDropdown={readOnly}
              withIcon={false}
            >
              <Icon icon="More" size={28} color={themeColors[themeMode].text} />
            </Dropdown>
          )}
        </div>
        <CustomModal
          open={showRenamePopup}
          onClose={() => {
            setShowRenamePopup(false);
          }}
          onClickPrimaryAction={handleEditAlias}
          primaryLabel="Rename"
          modalTitle="Rename"
        >
          <div className="renameContent">
            <TextField
              variant="outlined"
              label="File Name"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                style: {
                  borderRadius: "2px",
                  alignItems: "center",
                  fontFamily: "Hanken Grotesk, sans-serif",
                  fontSize: "16px",
                },
              }}
            />
          </div>
        </CustomModal>
        <CustomModal
          open={showDeletePopup}
          onClose={() => {
            setShowDeletePopup(false);
          }}
          onClickPrimaryAction={handleDelete}
          secondaryAction={true}
          onClickSecondaryAction={() => {
            setShowDeletePopup(false);
          }}
          primaryLabel="Confirm"
          secondaryLabe="Cancel"
          modalTitle="Delete"
        >
          <div className="deleteContent">
            <div className="deleteMsg">
              {deleteConfirmation?.confirmQuestion}
            </div>
            <div className="deleteFileName">
              {deleteConfirmation?.versionName}
            </div>
            <div className="release-datetime">
              {data?.uploadDate
                ? new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  }).format(new Date(data.uploadDate))
                : null}
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={showRenamePopup}
          onClose={() => {
            setShowRenamePopup(false);
          }}
          onClickPrimaryAction={handleEditAlias}
          primaryLabel="Rename"
          modalTitle="Rename"
        >
          <div className="renameContent">
            <TextField
              variant="outlined"
              label="File Name"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                style: {
                  borderRadius: "2px",
                  alignItems: "center",
                  fontFamily: "Hanken Grotesk, sans-serif",
                  fontSize: "16px",
                },
              }}
            />
          </div>
        </CustomModal>

        <CustomModal
          open={showEditPopup}
          onClose={() => {
            setShowEditPopup(false);
          }}
          onClickPrimaryAction={() => {
            changeHandler(selectedData);
            setShowDraftsList(true);
            setShowEditPopup(false);
            setRevision(false);
            setIsTransformed(false);
          }}
          primaryLabel="Edit"
          modalTitle="Edit"
        >
          <div className="renameContent">
            <div className="message">
              Choose a name for the draft File of "File Name"
            </div>
            <TextField
              variant="outlined"
              label="File Name"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                style: {
                  borderRadius: "2px",
                  alignItems: "center",
                  fontFamily: "Hanken Grotesk, sans-serif",
                  fontSize: "16px",
                },
              }}
            />
          </div>
        </CustomModal>
      </div>
      <div className="vertical-line" />
    </>
  );
};

export default LastPublishedRevision;
