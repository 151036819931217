import React, { useContext, useEffect, useState, useRef } from "react";
import { Collapse, IconButton } from "@mui/material";
import { useSelector, useStore } from "react-redux";
import { useTheme } from "../../../../theme/themeContext";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import ComponentButton from "../../../../components/ComponentButton";
import { ComponentsLibraries } from "../../services/common";
import "./index.scss";
import handleAddItem from "../ComponentsLayers/Preview/services/handleAddItem";
import { getIn } from "lodash-redux-immutability";
import Default from "./Assets/Default.svg";
import CustomCursorDiv from "../../../../components/CustomCursor";
import { AppContext } from "../../services/AppContext";
import Icon from "../../../../components/CustomIcon/Icon";
const ComponentsLibrary = () => {
  const { themeMode, themeColors } = useTheme();
  const componentNames = Object.keys(ComponentsLibraries);
  const librariesRef = useRef(null);
  const [insideParent, setInsideParent] = useState(null);
  const [openStates, setOpenStates] = useState(
    Object.fromEntries(componentNames.map((library) => [library, false]))
  );
  const [componentsToDisplay, setComponentsToDisplay] = useState([]);
  let store = useStore();

  let edit = useSelector((state) =>
    state?.entities?.editor ? state.entities.editor : {}
  );
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  const { parentToEdit: parent } = edit;
  const {
    insideInner,
    setInsideInner,
    targetRefEvent,
    setDragableType,
    dragParentDraged,
    dragParentHovered,
    listFab,
    bodyHeader,
    setKpiDetail,
    kpiDetail,
    SetNoComponent,
    noComponent,
    SetFilterHeaderForm,
    filterHeaderForm,
    setHeaderEvent,
    headerEvent,
    setBodyDetail,
    bodyDetail,
    bodyNotif,
  } = useContext(AppContext);
  let componentEdit = useSelector(
    (state) => state?.entities?.editor?.component || ""
  );
  // useEffect(() => {
  //   if (bodyNotif) {
  //     setOpenStates((prevState) =>
  //       Object.fromEntries(componentNames.map((library) => [library, false]))
  //     );
  //   }
  // }, [bodyNotif, componentNames]);
  useEffect(() => {
    setOpenStates((prevState) => {
      const updatedStates = {};
      for (const library of Object.keys(prevState)) {
        if (componentEdit === "body" && bodyNotif) {
          updatedStates[library] = false;
        } else {
          updatedStates[library] = library === componentEdit;
        }
      }
      return updatedStates;
    });
    librariesRef.current.scrollTo(0, 0);
  }, [componentEdit, componentNames]);

  const handleToggle = (library) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [library]: !prevState[library],
    }));
  };
  useEffect(() => {
    setComponentsToDisplay(
      bodyHeader
        ? ["tag"]
        : listFab
        ? ["form", "hform"]
        : kpiDetail
        ? ["list"]
        : filterHeaderForm
        ? ["media", "takegeoposition"]
        : headerEvent
        ? ["event"]
        : bodyDetail
        ? ["detail"]
        : []
    );
  }, [
    bodyHeader,
    listFab,
    kpiDetail,
    filterHeaderForm,
    headerEvent,
    bodyDetail,
  ]);
  const activeLibrary = bodyNotif
    ? [undefined]
    : componentNames.filter((library) => library === componentEdit);

  useEffect(() => {}, []);
  const sortedComponentNames = [
    ...(ComponentsLibraries[componentEdit] ? [componentEdit] : []),
    ...componentNames.filter((library) => library !== componentEdit).sort(),
  ].filter(Boolean);
  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  useEffect(() => {
    let insidePar = getIn(skeletonState, parentToEdit, null);
    setInsideParent(insidePar);
  }, [skeletonState, parentToEdit]);

  return (
    <div className="ComponentsLibrary">
      <div className="titleContainer">
        <div className="headerLabel">Components Library</div>
      </div>
      <div className="libraries" ref={librariesRef}>
        <>
          {typeof activeLibrary[0] === "undefined" && (
            <div className="noMenu">
              <Icon icon="noMoreComponents" size={22} color="#939298" />
              <div className="noComponent-desc">
                {componentEdit.charAt(0).toUpperCase() +
                  componentEdit.slice(1) +
                  " has no components."}
              </div>
            </div>
          )}
        </>
        {sortedComponentNames.map((library, index) => {
          const activeLib =
            activeLibrary[0]?.toString()?.toLowerCase() ===
            library?.toLowerCase();

          return (
            <>
              <div key={index} className="menu">
                <div
                  className="menuHeader"
                  onClick={() => handleToggle(library)}
                >
                  <div
                    style={{
                      color: activeLib
                        ? themeColors[themeMode].DefaultButtonLabel
                        : themeColors[themeMode].ButtonLabelDisable,
                      fontSize: 14,
                    }}
                  >
                    <IconButton
                      size="22px"
                      style={{
                        color: activeLib
                          ? themeColors[themeMode].DefaultButtonLabel
                          : themeColors[themeMode].ButtonLabelDisable,
                        padding: 0,
                        paddingRight: 8,
                        borderRadius: 0,
                      }}
                      disableRipple={true}
                    >
                      {openStates[library] ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                    {library.charAt(0).toUpperCase() + library.slice(1)}
                  </div>
                </div>
                <Collapse in={openStates[library]}>
                  <CustomCursorDiv svgCursor={Default}>
                    <div className="componentList">
                      {ComponentsLibraries[library] &&
                        Object.entries(ComponentsLibraries[library])
                          .filter(([componentName, _]) =>
                            bodyHeader
                              ? componentsToDisplay.includes(componentName)
                              : listFab
                              ? componentsToDisplay.includes(componentName)
                              : kpiDetail
                              ? componentsToDisplay.includes(componentName)
                              : filterHeaderForm
                              ? componentsToDisplay.includes(componentName)
                              : headerEvent
                              ? componentsToDisplay.includes(componentName)
                              : bodyDetail
                              ? componentsToDisplay.includes(componentName)
                              : true
                          )
                          .map(([componentName, componentProps], index) => {
                            return (
                              <React.Fragment key={index}>
                                <ComponentButton
                                  ComponentsLibraries={ComponentsLibraries}
                                  componentName={componentName}
                                  tooltip={componentProps?.title}
                                  handleAddItem={() =>
                                    handleAddItem(
                                      componentName,
                                      store,
                                      skeletonState,
                                      parent,
                                      insideParent,
                                      targetRefEvent
                                    )
                                  }
                                  activeLib={activeLib}
                                  label={componentProps?.title}
                                  {...componentProps}
                                  icon={componentProps?.icon}
                                  backgroundColor={
                                    activeLib
                                      ? themeColors[themeMode].iconBg
                                      : themeColors[themeMode].iconBgDisable
                                  }
                                  iconColor={
                                    activeLib
                                      ? themeColors[themeMode].icon
                                      : themeColors[themeMode].iconDisable
                                  }
                                  labelColor={
                                    activeLib
                                      ? themeColors[themeMode].iconLabel
                                      : themeColors[themeMode].iconLabelDisable
                                  }
                                />
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </CustomCursorDiv>
                </Collapse>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ComponentsLibrary;
