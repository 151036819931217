import React, { useContext, useEffect, useMemo, useState } from "react";
import { useStore, useSelector } from "react-redux";
import { navigateBack } from "../../../services/navigationMiddleware";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { getIn } from "lodash-redux-immutability";
import Toolbar from "../../Toolbar";
import Modal from "../../Modal";
import Sidebar from "../../Sidebar";
import Swipe from "../../Swipe";
import imxtools from "imxtools";
import * as Views from "./Views";
import ListView from "./Views/List View";
import Fab from "../../Fab";
import "./index.scss";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import HeaderClassification from "../../HeaderClassification";
import DropTargetBody from "../../DropTargetBody";
import { AppContext } from "../../../../../../services/AppContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import InspectionModalOld from "../../InspectionModalOld";
import InspectionModal from "../../InspectionModal";
import Path from "../../Path";
import Form from "../../Form";
import useToasts from "../../../../../../../../components/CustomToast/useToasts";

const ListScreen = () => {
  const [data, setData] = useState({});
  const [listData, setListData] = useState({});
  const [pathApp, setPathApp] = useState([]);
  const [pathList, setPathList] = useState([]);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  const [currentView, setCurrentView] = useState("ListView");
  const [listLength, setLlistLength] = useState("--");
  const [openModalSidebar, setOpenModalSidebar] = useState(false);
  const [openModalSwipe, setOpenModalSwipe] = useState(false);
  const {
    setTargetRefEvent,
    targetRefEvent,
    openInspection,
    setOpenInspection,
    setBodyHeader,
    setHeaderEvent,
  } = useContext(AppContext);
  const [hoverHeader, setHoverHeader] = useState(false);
  const [dataAttributes, setDataAttributes] = useState(
    imxtools?.toArray(data?.attribute)
  );
  const [isOpen, setIsOpen] = useState(false);
  let store = useStore();
  let edit = useSelector((state) => state?.entities?.editor);
  const { skeleton, language, parentToEdit, navigationPath } = useSelector(
    (state) => ({
      skeleton: state?.entities?.explorer?.skeleton || {},
      language: state?.entities?.deviceview?.settings?.language || "en",
      parentToEdit: state?.entities?.editor?.parentToEdit || [],
      navigationPath: state?.entities?.deviceview?.navigationPath || [],
    })
  );
  const { addToast } = useToasts();
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const [openForm, setOpenForm] = useState(false);
  const [openDetailForm, setOpenDetailForm] = useState(false);
  const [formHform, setFormHform] = useState(null);
  const [targetForm, setTargetForm] = useState("");
  const [formsData, setformsData] = useState([]);
  const [hformsData, setHformsData] = useState([]);
  const [targetFormIndex, setTargetFormIndex] = useState(null);

  useEffect(() => {
    const { path } = navigationPath?.[navigationPath.length - 1]?.props;

    const AppData = getIn(skeleton ? skeleton : {}, path);
    setData(AppData);
    setPathApp(path);

    if (AppData?.list) {
      const ListData = getIn(skeleton ? skeleton : {}, [...path, "list"]);
      setListData(ListData);
      setPathList([...path, "list"]);
    }
  }, [navigationPath, skeleton]);

  useEffect(() => {
    const listData = getIn(skeleton ? skeleton : {}, pathList);
    const forms = imxtools?.toArray(listData?.form ? listData?.form : []);
    const hforms = imxtools?.toArray(listData?.hform ? listData?.hform : []);

    setForms(forms);
    setHforms(hforms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    setformsData(
      imxtools.toArray(getIn(skeleton, [...pathList, "form"]) || [])
    );

    setHformsData(
      imxtools.toArray(getIn(skeleton, [...pathList, "hform"]) || [])
    );
  }, [pathList, formsData, hformsData]);
  const handleBodyDoubleClick = (e) => {
    // Example of setting target ref event for the body
    const event = {
      targetref: listData?.detail?.event?.targetref, // Replace with actual target ref for the body
    };
    if (listData?.detail?.event?.targetref) {
      handleForm(e, null, event);
    } else {
      addToast("you should add targetref to the event !", "warn");
    }
  };
  const handleForm = (e, indexEvent, event) => {
    if (indexEvent === null) {
      if (event && event.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    } else {
      if (event && event?.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    }
  };
  useEffect(() => {
    const forms = imxtools?.toArray(listData?.form ? listData?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(listData?.hform ? listData?.hform : []);
    setHforms(hforms);
  }, [listData]);
  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;

    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [
    listData,
    hformsData,
    formsData,
    targetRefEvent,
    targetForm,
    listData?.media,
  ]);

  const handleRenderView = (event, view) => {
    event.stopPropagation();
    setCurrentView(view);
  };

  const handleOpenActions = (event, open) => {
    event.stopPropagation();
    setTargetRefEvent(event);
    setOpenModalSwipe(open);
  };

  const handleOpenSidebar = (event, open) => {
    event.stopPropagation();
    setOpenModalSidebar(open);
  };

  const RenderViewComponent = (props) => {
    const TargetComponent = Views[currentView] || ListView;
    return <TargetComponent {...props} />;
  };

  let viewProps = {
    data: data,
    path: pathList,
    listData: listData,
    readOnly: readOnly,
    setLlistLength,
    handleOpenActions,
    currentPath: parentToEdit,
    setIsOpen: setIsOpen,
    setOpenForm: setOpenForm,
  };

  const closeModal = () => {
    setOpenInspection(false);
  };

  const renderList = useMemo(() => {
    return (
      <React.Fragment>
        {data?.list ? (
          <DropTargetBody
            className="drop-list-zone"
            accept={[
              "section",
              "fab",
              "toolbar",
              "detail",
              "swipe",
              "body",
              "calendar",
            ]}
            dragParent={pathList}
          >
            <div
              className="headerList"
              style={{
                flexDirection:
                  language?.toLowerCase() === "ar" && "row-reverse",
              }}
            >
              <div
                className="goBack"
                onClick={() => {
                  navigateBack(store);
                  setBodyHeader(false);
                  setHeaderEvent(false);
                }}
              >
                <Icon
                  icon={
                    language?.toLowerCase() === "ar"
                      ? "chevron-right"
                      : "chevron-left"
                  }
                  size={20}
                />
              </div>
              <div
                className={`appTitle ${
                  isSelectedComponent(readOnly, pathList, parentToEdit) &&
                  "selectedComponent"
                }`}
                onClick={(e) => {
                  handleComponentProp(
                    e,
                    null,
                    listData,
                    pathApp,
                    store,
                    "list"
                  );
                  setHeaderEvent(false);
                }}
                style={{
                  minWidth: listData.header && !readOnly && "calc(100% - 95px)",
                  maxWidth: listData.header && !readOnly && "calc(100% - 95px)",
                }}
              >
                {" "}
                <>
                  <span className="List-label">
                    {listData?.label
                      ? getLabelByLanguage(language, listData)
                      : "Add label"}
                  </span>{" "}
                  <span className="list-length">
                    {listData?.body ? listLength : "N/A"}
                  </span>
                </>
              </div>
              {listData.header && (
                <div
                  className={`header-clssification ${
                    !readOnly && !hoverHeader && !openDetailForm && "hovered"
                  }`}
                  onClick={() => setHeaderEvent(true)}
                >
                  <HeaderClassification
                    data={listData?.header}
                    path={pathList}
                    setHoverHeader={setHoverHeader}
                    openDetailForm={openDetailForm}
                    setOpenDetailForm={setOpenDetailForm}
                  />
                </div>
              )}
              {!readOnly && (
                <div
                  className="goBack"
                  onClick={(e) => {
                    handleComponentProp(
                      e,
                      null,
                      listData,
                      pathApp,
                      store,
                      "list"
                    );
                    setOpenModalSidebar(true);
                    setHeaderEvent(false);
                  }}
                >
                  <Icon icon="list-mode" size={20} />
                </div>
              )}
            </div>
            {(listData?.search?.toLowerCase() === "true" ||
              listData?.toolbar) && (
              <div
                className="toolbar-container"
                onClick={() => setHeaderEvent(false)}
              >
                <Toolbar
                  data={listData?.toolbar}
                  path={pathList}
                  searchStatus={listData?.search?.toLowerCase() === "true"}
                  readOnly={readOnly}
                  currentPath={parentToEdit}
                  listData={listData}
                />
              </div>
            )}
            {listData?.path && (
              <DropTargetBody
                className={`path-clssification  ${
                  isSelectedComponent(
                    readOnly,
                    [...pathList, "path"],
                    edit?.parentToEdit
                  )
                    ? `selectedComponent`
                    : !readOnly && `unSelectedComponent`
                } `}
                onClick={(e) => {
                  handleComponentProp(
                    e,
                    null,
                    listData?.path,
                    pathList,
                    store,
                    "path"
                  );
                  setHeaderEvent(false);
                }}
                dragParent={pathList}
                accept={["attribute"]}
              >
                {!listData?.path?.attribute ? (
                  <div className="empty-path">Path</div>
                ) : (
                  <Path
                    data={listData?.path}
                    path={pathList}
                    currentPath={parentToEdit}
                  />
                )}
              </DropTargetBody>
            )}{" "}
            {listData?.detail && listData?.mboname == "CLASSSTRUCTURE" && (
              <DropTargetBody
                className={`detail-clssification  ${
                  isSelectedComponent(
                    readOnly,
                    [...pathList, "detail"],
                    edit?.parentToEdit
                  )
                    ? `selectedComponent`
                    : !readOnly && `unSelectedComponent`
                } `}
                onClick={(e) => {
                  handleComponentProp(
                    e,
                    null,
                    listData?.detail,
                    pathList,
                    store,
                    "detail"
                  );
                  setHeaderEvent(false);
                }}
                dragParent={pathList}
                accept={["event"]}
                style={{
                  justifyContent: !listData?.detail.event ? "center" : "end",
                  padding: listData?.detail.event && "6px",
                  paddingRight: listData?.detail.event && "5px",
                }}
              >
                {!listData?.detail.event ? (
                  <div className="empty-detail">Detail</div>
                ) : (
                  imxtools
                    ?.toArray(
                      listData?.detail.event ? listData?.detail.event : []
                    )
                    ?.map((event, indexEvent) => {
                      const numEvent = imxtools?.toArray(
                        listData?.detail.event
                      )?.length;
                      return (
                        <div
                          className={`event-container  ${
                            isSelectedComponent(
                              readOnly,
                              [...pathList, "detail", "event"],
                              edit?.parentToEdit
                            )
                              ? `selectedComponent`
                              : !readOnly && `unSelectedComponent`
                          } `}
                          onClick={(event) => {
                            handleComponentProp(
                              event,
                              null,
                              listData?.detail?.event,
                              [...pathList, "detail"],
                              store,
                              "event"
                            );
                            setHeaderEvent(false);
                          }}
                          onDoubleClick={(e) => {
                            handleForm(
                              e,
                              numEvent > 1 ? indexEvent?.toString() : null,
                              event
                            );
                          }}
                          onMouseEnter={() => setHoverHeader(true)}
                          onMouseLeave={() => setHoverHeader(false)}
                        >
                          <Icon icon="Event" size={24} />
                        </div>
                      );
                    })
                )}
              </DropTargetBody>
            )}
            <div
              onDoubleClick={
                listData?.detail &&
                listData?.mboname == "CLASSSTRUCTURE" &&
                handleBodyDoubleClick
              }
              onClick={(e) => {
                handleComponentProp(e, null, listData, pathApp, store, "list");
                setBodyHeader(false);
                setHeaderEvent(false);
              }}
              className={
                listData?.toolbar || listData?.search?.toLowerCase() === "true"
                  ? "list-body-toolbar"
                  : listData?.body
                  ? "list-body"
                  : "list-body-empty"
              }
            >
              {RenderViewComponent(viewProps)}
            </div>
          </DropTargetBody>
        ) : (
          <div className="drop-list-zone">
            <div className="headerList">
              <div
                className="goBack"
                onClick={() => {
                  navigateBack(store);
                  setHeaderEvent(false);
                }}
              >
                <Icon icon="chevron-left" size={20} />
              </div>
            </div>
            <DropTargetBody
              accept={["list"]}
              dragParent={pathApp}
              className="drop-empty-list-zone"
              onClick={(e) => {
                setHeaderEvent(false);
                handleComponentProp(e, null, data, pathApp, store, "app", true);
              }}
            >
              <div className="empty-list">
                {readOnly ? "No List here" : "Drop List here"}
              </div>
            </DropTargetBody>
          </div>
        )}

        {/* Fab */}
        {listData?.fab && (
          <Fab
            data={listData?.fab}
            readOnly={readOnly}
            path={pathList}
            forms={forms}
            currentPath={parentToEdit}
          />
        )}

        {/* Sidebar */}
        <Modal openModal={openModalSidebar}>
          <Sidebar
            data={imxtools?.toArray(listData?.whereclause)}
            fullData={data}
            handleOpenSidebar={handleOpenSidebar}
            handleListClick={(e) =>
              handleComponentProp(e, null, data, pathList, store, "list")
            }
            handleRenderView={handleRenderView}
            currentView={currentView}
            path={pathList}
            readOnly={readOnly}
            currentPath={parentToEdit}
            classification={listData?.hasImages?.toLowerCase() === "true"}
            maximomedia={imxtools?.toArray(listData?.maximomedia || [])}
          />
        </Modal>

        {/* Swipe */}
        <Modal openModal={openModalSwipe}>
          <Swipe
            data={listData?.swipe}
            path={pathList}
            handleOpenActions={handleOpenActions}
            readOnly={readOnly}
            forms={[...forms, ...hforms]}
            formsData={forms}
            hformsData={hforms}
            numForms={forms?.length}
            currentPath={parentToEdit}
          />
        </Modal>
        <Modal openModal={openInspection} tab={true} app={true}>
          {listData?.inspection?.version === "V2" ? (
            <InspectionModalOld
              closeModal={closeModal}
              data={listData?.inspection}
              path={[...pathList, "inspection"]}
            />
          ) : (
            <InspectionModal
              closeModal={closeModal}
              data={listData?.inspection}
              path={[...pathList, "inspection"]}
            />
          )}
        </Modal>
        <Modal openModal={openForm} tab={true} header={true}>
          <Form
            data={targetForm}
            path={
              formHform === "form"
                ? formsData?.length === 1
                  ? [...pathList, formHform]
                  : [...pathList, formHform, targetFormIndex]
                : hformsData?.length === 1
                ? [...pathList, formHform]
                : [...pathList, formHform, targetFormIndex]
            }
            index={forms?.length === 1 ? null : targetFormIndex?.toString()}
            parentPath={pathList}
            component={formHform}
            setOpenForm={setOpenForm}
            readOnly={readOnly}
            inGis={false}
            header={true}
            hform={formHform === "hform"}
          />
        </Modal>
      </React.Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewProps]);
  return renderList;
};

export default ListScreen;
