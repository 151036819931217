import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useStore } from "react-redux";
import { useSelector } from "react-redux";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import DropTargetBody from "../DropTargetBody";
import { getIn } from "lodash-redux-immutability";
import imxtools from "imxtools";
import { AppContext } from "../../../../../services/AppContext";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import Form from "../Form";
import Modal from "../Modal";
function HeaderClassification({
  data = {},
  path = [],
  setHoverHeader = () => {},
  openDetailForm = false,
  setOpenDetailForm = () => {},
}) {
  let store = useStore();
  let edit = useSelector((state) => state?.entities?.editor);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);
  const [openForm, setOpenForm] = useState(false);
  const [formHform, setFormHform] = useState(null);
  const [targetForm, setTargetForm] = useState("");
  const [formsData, setformsData] = useState([]);
  const [hformsData, setHformsData] = useState([]);
  const [targetFormIndex, setTargetFormIndex] = useState(null);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);

  const { targetRefEvent, setTargetRefEvent, headerEvent, setHeaderEvent } =
    useContext(AppContext);
  const { addToast } = useToasts();

  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  useEffect(() => {
    setformsData(
      imxtools.toArray(getIn(skeletonState, [...path, "form"]) || [])
    );

    setHformsData(
      imxtools.toArray(getIn(skeletonState, [...path, "hform"]) || [])
    );
  }, [path, formsData, hformsData]);
  const handleForm = (e, indexEvent, event) => {
    if (indexEvent === null) {
      if (event && event.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenDetailForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    } else {
      if (event && event?.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenDetailForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    }
  };
  useEffect(() => {
    const forms = imxtools?.toArray(data?.form ? data?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(data?.hform ? data?.hform : []);
    setHforms(hforms);
  }, [data]);
  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;

    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [data, hformsData, formsData, targetRefEvent, targetForm, data?.media]);

  return (
    <React.Fragment>
      <DropTargetBody
        className={`headerClassification  ${
          isSelectedComponent(readOnly, [...path, "header"], edit?.parentToEdit)
            ? `selectedComponent`
            : !readOnly && `unSelectedComponent`
        }`}
        onClick={(event) => {
          handleComponentProp(event, null, data, path, store, "header");
          setHeaderEvent(true);
        }}
        accept={"event"}
        dragParent={path}
      >
        {data?.event ? (
          imxtools
            ?.toArray(data?.event ? data?.event : [])
            ?.map((event, indexEvent) => {
              const numEvent = imxtools?.toArray(data?.event)?.length;
              return (
                <div
                  className={`event-classification  ${
                    !readOnly && !openDetailForm && "hovered"
                  }`}
                  onClick={(event) =>
                    handleComponentProp(
                      event,
                      null,
                      data?.event,
                      [...path, "header"],
                      store,
                      "event"
                    )
                  }
                  onDoubleClick={(e) => {
                    handleForm(
                      e,
                      numEvent > 1 ? indexEvent?.toString() : null,
                      event
                    );
                  }}
                  onMouseEnter={() => setHoverHeader(true)}
                  onMouseLeave={() => setHoverHeader(false)}
                >
                  <Icon icon="add" size={17} />
                </div>
              );
            })
        ) : (
          <div className="empty-header">Drop Event</div>
        )}
      </DropTargetBody>
      <Modal openModal={openDetailForm} tab={true} header={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData?.length === 1
                ? [...path, formHform]
                : [...path, formHform, targetFormIndex]
              : hformsData?.length === 1
              ? [...path, formHform]
              : [...path, formHform, targetFormIndex]
          }
          index={forms?.length === 1 ? null : targetFormIndex?.toString()}
          parentPath={path}
          component={formHform}
          setOpenForm={setOpenDetailForm}
          readOnly={readOnly}
          inGis={false}
          header={true}
          hform={formHform === "hform"}
        />
      </Modal>
    </React.Fragment>
  );
}

export default HeaderClassification;
