import React, { useState } from "react";

export const AppContext = React.createContext();

const ContextDnD = ({ children }) => {
  const [insideInner, setInsideInner] = useState([]);

  const [hoveredData, setHoveredData] = useState(null);
  const [dragedData, setDragedData] = useState(null);
  const [dragParentHovered, setDragParentHovered] = useState([
    "maxapps",
    "page",
  ]);
  const [openInspection, setOpenInspection] = useState(false);
  const [dragParentDraged, setDragParentDraged] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dragedIndex, setDragedIndex] = useState(null);
  const [dragPath, setDragPath] = useState(null);
  const [typeAction, setTypeAction] = useState("");
  const [isDragingIn, setIsDragingIn] = useState(false);
  const [targetRefEvent, setTargetRefEvent] = useState(null);
  const [dragableType, setDragableType] = useState("");
  const [listFab, setListFab] = useState(false);
  const [kpiDetail, setKpiDetail] = useState(false);
  const [bodyHeader, setBodyHeader] = useState(false);
  const [emptyKey, setEmptyKey] = useState(false);
  const [emptyValue, setEmptyValue] = useState(false);
  const [emptyIndex, setEmptyIndex] = useState(null);
  const [eventApp, setEventApp] = useState(false);
  const [noComponent, SetNoComponent] = useState(false);
  const [filterHeaderForm, SetFilterHeaderForm] = useState(false);
  const [copyedProp, setCopyProp] = useState(false);
  const [copyedComp, setCopyComp] = useState(false);
  const [headerEvent, setHeaderEvent] = useState(false);
  const [bodyDetail, setBodyDetail] = useState(false);
  const [bodyNotif, setBodyNotif] = useState(false);
  return (
    <AppContext.Provider
      value={{
        insideInner,
        setInsideInner,
        hoveredData,
        setHoveredData,
        dragedData,
        setDragedData,
        dragParentHovered,
        setDragParentHovered,
        dragParentDraged,
        setDragParentDraged,
        hoveredIndex,
        setHoveredIndex,
        dragedIndex,
        setDragedIndex,
        dragPath,
        setDragPath,
        typeAction,
        setTypeAction,
        isDragingIn,
        setIsDragingIn,
        targetRefEvent,
        setTargetRefEvent,
        dragableType,
        setDragableType,
        openInspection,
        setOpenInspection,
        listFab,
        setListFab,
        setBodyHeader,
        bodyHeader,
        kpiDetail,
        setKpiDetail,
        setEmptyValue,
        emptyValue,
        setEmptyKey,
        emptyKey,
        setEmptyIndex,
        emptyIndex,
        setEventApp,
        eventApp,
        noComponent,
        SetNoComponent,
        filterHeaderForm,
        SetFilterHeaderForm,
        copyedComp,
        copyedProp,
        setCopyComp,
        setCopyProp,
        headerEvent,
        setHeaderEvent,
        setBodyDetail,
        bodyDetail,
        setBodyNotif,
        bodyNotif,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextDnD;
