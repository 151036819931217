import React from "react";
import "./index.scss";
import MediaForm from "./Media";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import DropTargetBody from "./../DropTargetBody";
import { arraysAreEqual } from "../../../../../../../utils/util";

function HeaderForm({ data = {}, path = [] }) {
  let store = useStore();
  let edit = useSelector((state) => state?.entities?.editor);

  return (
    <div
      className="media-content"
      style={{
        justifyContent: data?.takegeoposition && !data?.media && "flex-end",
      }}
    >
      {data?.media ? (
        <div
          className={
            arraysAreEqual([...path, "media"], edit.parentToEdit)
              ? `media-bar  ${"selectedComponent"}`
              : `media-bar`
          }
          style={{
            width: "auto",
            maxWidth: "80%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          onClick={(event) =>
            handleComponentProp(event, null, data?.media, path, store, "media")
          }
        >
          <DropTargetBody
            accept={["gallery", "audio", "video", "image", "file"]}
            dragParent={path}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <MediaForm data={data?.media} path={[...path, "media"]} />
          </DropTargetBody>
        </div>
      ) : (
        <></>
      )}
      {data?.takegeoposition ? (
        <div
          className={
            arraysAreEqual([...path, "takegeoposition"], edit.parentToEdit)
              ? `geoposition  ${"selectedComponent"}`
              : `geoposition`
          }
          onClick={(event) =>
            handleComponentProp(
              event,
              null,
              data?.takegeoposition,
              path,
              store,
              "takegeoposition"
            )
          }
        >
          <Icon icon={"location_solid"} size={30} color="#c8c8c8" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HeaderForm;
