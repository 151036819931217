import React, { useContext, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import imxtools from "imxtools";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../services/handleComponentProperties";
import "./index.scss";
import Modal from "../Modal";
import { useState } from "react";
import { getIn } from "lodash-redux-immutability";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import Form from "../Form";
import DropTargetBody from "../DropTargetBody";
import { AppContext } from "../../../../../services/AppContext";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";
import { navigateTo } from "../../services/navigationMiddleware";

function Timer({
  data,
  path = [],
  readOnly = true,
  forms = [],
  tab = false,
  currentPath = [],
  tabEnabled = false,
  parentData,
}) {
  const { theme, themes } = useDeviceTheme();

  let store = useStore();
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  const { targetRefEvent, setTargetRefEvent, setHeaderEvent } =
    useContext(AppContext);
  const { addToast } = useToasts();

  const handleComp = (e, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };

  let edit = useSelector((state) => state?.entities?.editor);

  function handleNavigateTo(e) {
    e.stopPropagation();

    //handleComponentProp(e, null, data, path, store, "detail");
    navigateTo(store, "TimerDetail", {
      data: data,
      path: [...path],
    });
  }

  return (
    <React.Fragment>
      <div
        className={`timer-container ${
          arraysAreEqual([...path, "timer"], edit?.parentToEdit)
            ? !readOnly && "selectedComponent"
            : !readOnly && "unSelectedComponent"
        }`}
        onClick={(e) => {
          handleComp(e, [...path, "timer"], "timer");
          setHeaderEvent(false);
        }}
        style={{ right: parentData?.list?.fab && "22%" }}
      >
        <DropTargetBody
          className="drop-timer-zone"
          dropPath={[...path, "timer"]}
          accept={""}
          style={{ height: "100%", width: "100%" }}
        >
          {tab === true
            ? imxtools?.toArray(data)?.length > 0 &&
              imxtools?.toArray(data)?.map((timer, index) => {
                return (
                  <div
                    key={index}
                    className="timer-button"
                    onClick={(e) => handleComp(e, [...path, "timer"], "timer")}
                    style={{
                      backgroundColor: tab && "white",
                      boxShadow: tab && "0 0 8px rgba(72, 72, 72, 0.3)",
                      padding: 3,
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>
                );
              })
            : Object?.keys(data)?.length > 0 && (
                <div
                  className={`timer-button ${!readOnly && "hovered"}`}
                  style={{
                    backgroundColor: "var(--primary)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 0 8px #2222224d",
                  }}
                  onClick={(e) => {
                    handleComponentProp(e, null, data, path, store, "timer");
                    setHeaderEvent(false);
                  }}
                  onDoubleClick={(e) => handleNavigateTo(e)}
                >
                  <Icon icon="start-timer" size={24} color="white" />
                </div>
              )}
        </DropTargetBody>
      </div>
    </React.Fragment>
  );
}

export default Timer;
