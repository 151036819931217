import React, { useContext, useRef } from "react";
import { useDrop } from "react-dnd";
import { useSelector, useStore } from "react-redux";
import handleAddItem from "../handleAddItem";
import { setIn } from "lodash-redux-immutability";
import "./index.scss";
import { AppContext } from "../../../../../services/AppContext";
import _ from "lodash";
import { arraysAreEqual } from "../../../../../../../utils/util";

const moveItemToEndOfList = (oldData, itemOrder) => {
  const clonedData = _.cloneDeep(oldData);

  if (!Array.isArray(clonedData.app)) {
    console.error("Invalid oldData structure");
    return oldData;
  }

  const item = clonedData.app.find((item) => item?.itemOrder === itemOrder);

  if (!item) {
    console.error("Item not found");
    return oldData;
  }

  clonedData.app = clonedData.app.filter(
    (item) => item?.itemOrder !== itemOrder
  );

  clonedData.app.push(item);

  return clonedData;
};

const DropTarget = ({
  children,
  accept = [],
  style = {},
  className = "",
  dropPath = [],
  dragParentPath,
  pages = [],
  currentPage,
  homeScreen,
  onClick = () => {},
}) => {
  const {
    insideInner,
    setInsideInner,
    dragParentHovered,
    setDragParentHovered,
  } = useContext(AppContext);

  let store = useStore();
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  let parentToEdit = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);

  const [, drop] = useDrop({
    accept: accept,

    drop: (item = { dragParentPath: [] }) => {
      if (
        dragParentHovered?.[dragParentHovered?.length - 1] !== "page" &&
        !homeScreen
      ) {
        return;
      }
      if (arraysAreEqual(insideInner, item.dragParentPath)) {
        if (isNaN(item.dragParentPath[item.dragParentPath.length - 1])) {
          let OldData = _.get(skeleton, item.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });
        } else {
          let OldData = _.get(skeleton, item.dragParentPath);
          let SortedData = moveItemToEndOfList(
            OldData,
            item?.dragData?.itemOrder
          );
          let newData = setIn(
            skeleton,
            [...item.dragParentPath, "app"],
            SortedData?.app
          );
          store.dispatch({
            type: "explorer/dataAdded",
            payload: {
              skeleton: newData,
              readOnly: false,
            },
          });
        }
        return;
      } else {
        if (insideInner.includes("module") || insideInner.includes("row")) {
          return;
        }

        if (item?.fromComponentLib) {
          handleAddItem(item.type, store, skeletonState, parentToEdit);
        } else {
          if (homeScreen) {
            return;
          }
          store.dispatch({
            type: "explorer/moveItem",
            payload: {
              globalSkeloton: skeleton,
              parent: dropPath,
              key: item.type,
              dragData: item.dragData,
              dragPath: item.dragPath,
              dragParentPath: item.dragParentPath,
            },
          });
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      setDragParentHovered(dragParentPath);

      if (pages?.length === 2 && (pages?.[0]?.gis || pages?.[1]?.gis)) {
        if (pages?.length === 1) {
          setInsideInner(["maxapps", "page"]);
        } else {
          setInsideInner(["maxapps", "page", String(currentPage)]);
        }
      } else {
        if (pages.length > 1) {
          setInsideInner(["maxapps", "page", String(currentPage)]);
        } else {
          setInsideInner(["maxapps", "page"]);
        }
      }
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const ref = useRef();
  drop(ref);
  const finalStyle = {
    ...style,
  };
  return (
    <div style={finalStyle} className={className} ref={ref} onClick={onClick}>
      {children}
    </div>
  );
};

export default DropTarget;
