import React, { useContext, useEffect, useState } from "react";
import imxtools from "imxtools";
import "./index.scss";
import Section from "../Section";
import ListDetail from "../ListDetail";
import Toolbar from "../Toolbar";
import { AppContext } from "../../../../../services/AppContext";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { useStore } from "react-redux";
import DropTargetBody from "../DropTargetBody";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { Map } from "google-maps-react";
import { ComponentsLibraries } from "../../../../../services/common";
import Fab from "../Fab";
import Modal from "../Modal";
import Swipe from "../Swipe";
import { Height } from "@mui/icons-material";
import useRandomData from "../Attribute/useRandomData";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import CarouselImag1 from "../../../../../../../assets/carrousssel-img1.png";
import lightBackground from "../../../../../../../assets/support-light.png";
import { getIn, setIn } from "lodash-redux-immutability";
import { useSelector } from "react-redux";
import Timer from "../Timer";
function Tab({
  data = [],
  dataTab = [],
  currentTab = 0,
  path = [],
  readOnly = false,
  currentPath = [],
}) {
  let store = useStore();

  const { setIsDragingIn, setBodyDetail } = useContext(AppContext);
  const [openModalSwipe, setOpenModalSwipe] = useState(false);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  const [filtredCurrentTab, setFiltredCurrentTab] = useState([]);
  const [dataAttributes, setDataAttributes] = useState([]);
  const [pathLength, setPathLeng] = useState(Object?.keys(currentPath)?.length);
  const images = [
    { src: CarouselImag1, alt: "Image 1" },
    { src: lightBackground, alt: "Image 2" },
  ];
  let skeleton = useSelector((state) => state?.entities?.explorer?.skeleton);
  useEffect(() => {
    setDataAttributes(imxtools?.toArray(data[currentTab]?.section));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[currentTab]?.section, data, currentTab]);
  useEffect(() => {
    const forms = imxtools?.toArray(data?.form ? data?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(data?.hform ? data?.hform : []);
    setHforms(hforms);
  }, [data]);

  const handleComponentp = (newPath, component, data) => {
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };

  useEffect(() => {
    const filtredCurrentTab = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (key !== "id" && key !== "itemOrder") {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    setFiltredCurrentTab(filtredCurrentTab);
  }, [currentTab, dataTab]);

  const handleOpenActions = (event, open) => {
    event.stopPropagation();
    setOpenModalSwipe(open);
  };
  const randomDate = useRandomData("DATETIME");
  const randomName = useRandomData("ALN");
  return (
    <div
      className="tab-container"
      onClick={(e) => {
        handleComponentp(path, "tab", data[currentTab]);
        setBodyDetail(false);
      }}
    >
      {Object?.keys(filtredCurrentTab)?.length > 0 ? (
        data[currentTab]?.geopositioning ? (
          <div className="Map-View">
            <Map
              google={window.google}
              className={pathLength <= 7 ? "TabMap" : "TabMapRelation"}
              zoom={14}
              disableDefaultUI={true}
              initialCenter={{ lat: 37.7749, lng: -122.4194 }}
              mapType={"street"}
              styles={[
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [
                    {
                      visibility: "off",
                    },
                  ],
                },
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [
                    {
                      visibility: "off",
                    },
                  ],
                },
                // Add more styles to hide labels for other features if needed
              ]}
              style={{
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
                height: pathLength <= 7 ? "96%" : "100%",
                maxHeight: pathLength <= 7 ? "96%" : "100%",
                minHeight: pathLength <= 7 ? "96%" : "100%",
              }}
            />
          </div>
        ) : (
          <DropTargetBody
            accept={[
              "section",
              "toolbar",
              "timer",
              "list",
              "geopositioning",
              "fab",
              "carroussel",
              "signature",
            ]}
            dragParent={path}
            className="tab-container"
          >
            {data[currentTab]?.toolbar && (
              <div className="toolbar-container">
                <Toolbar
                  data={data[currentTab]?.toolbar}
                  path={path}
                  searchStatus={
                    data[currentTab]?.search?.toLowerCase() === "true"
                  }
                  readOnly={readOnly}
                  currentPath={currentPath}
                />
              </div>
            )}

            {imxtools?.toArray(data[currentTab]?.section)?.length > 0 &&
              imxtools
                ?.toArray(data[currentTab]?.section)
                ?.map((section, indexSection) => {
                  const numSetion = imxtools?.toArray(
                    data[currentTab]?.section
                  )?.length;
                  return (
                    <React.Fragment key={indexSection}>
                      <DragItemAttribute
                        index={indexSection}
                        className="drag-section-zone"
                        component={"section"}
                        dragParentPath={path}
                        dragData={section}
                        dataAttributes={dataAttributes}
                        setDataAttributes={setDataAttributes}
                        setIsDraging={setIsDragingIn}
                      >
                        <Section
                          data={section}
                          path={path}
                          display={section?.display}
                          readOnly={readOnly}
                          numSetion={numSetion}
                          indexSection={indexSection}
                          currentPath={currentPath}
                        />
                      </DragItemAttribute>
                    </React.Fragment>
                  );
                })}

            {data[currentTab].carroussel && (
              <DropTargetBody
                className={`carroussel-container ${
                  isSelectedComponent(
                    readOnly,
                    [...path, "carroussel"],
                    currentPath
                  ) && "selectedComponent"
                }`}
                onClick={(e) =>
                  handleComponentProp(
                    e,
                    null,
                    data[currentTab].carroussel,
                    path,
                    store,
                    "carroussel"
                  )
                }
                dragParent={path}
              >
                <div className="carroussel-body">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    pagination={{ clickable: true }}
                    //autoplay={{ delay: 3000 }}
                    loop={true}
                    allowTouchMove={false}
                  >
                    {" "}
                    {images.map((image, index) => (
                      <SwiperSlide key={index} className="swipeSlide-container">
                        <div className="image-index">
                          {index + 1} / {images.length}
                        </div>
                        <img
                          src={image.src}
                          alt={image.alt}
                          style={{ width: "235px", height: "235px" }}
                        />
                      </SwiperSlide>
                    ))}
                    <div className="fullScreen">
                      <Icon icon="fullscreen" size={24} color="#fff" />
                    </div>
                    {(!data[currentTab].carroussel?.edit ||
                      data[currentTab].carroussel?.edit == "true") && (
                      <div className="editImage">
                        <Icon icon="editgallery" size={24} color="#fff" />
                      </div>
                    )}
                  </Swiper>
                </div>
              </DropTargetBody>
            )}
            {data[currentTab].signature && (
              <div
                style={{
                  width: " 100%",
                  padding: "0 5px",
                }}
              >
                <DropTargetBody
                  className={`signature-container ${
                    isSelectedComponent(
                      readOnly,
                      [...path, "signature"],
                      currentPath
                    ) && "selectedComponent"
                  }`}
                  onClick={(e) =>
                    handleComponentProp(
                      e,
                      null,
                      data[currentTab].signature,
                      path,
                      store,
                      "signature"
                    )
                  }
                  dragParent={path}
                >
                  <div className="signature-icon">
                    <Icon icon="tabSignature" size={45} color="#535353" />
                  </div>
                  <div className="signature-body">
                    <div className="signature-label">
                      {"Created by : " + randomName}
                    </div>
                    <div className="signature-date">{randomDate}</div>
                  </div>
                </DropTargetBody>
              </div>
            )}
            {data[currentTab]?.list &&
              imxtools
                ?.toArray(data[currentTab]?.list)
                ?.map((listDetail, index) => {
                  const numListDetail = imxtools?.toArray(
                    data[currentTab]?.list
                  )?.length;
                  return (
                    <DropTargetBody
                      key={index}
                      className="tab-list"
                      onClick={(e) =>
                        handleComponentProp(e, null, data, path, store, "list")
                      }
                      accept={[
                        "body",
                        "toolbar",
                        "fab",
                        "swipe",
                        "detail",
                        "geopositioning",
                      ]}
                      dragParent={path}
                    >
                      <ListDetail
                        data={listDetail}
                        path={
                          numListDetail > 1
                            ? [...path, "list", index?.toString()]
                            : [...path, "list"]
                        }
                        readOnly={readOnly}
                        currentPath={currentPath}
                        tabLabel={
                          data[currentTab]?.label
                            ? data[currentTab]?.label
                            : "--"
                        }
                      />
                    </DropTargetBody>
                  );
                })}

            {data[currentTab]?.timer && (
              <Timer
                data={data[currentTab]?.timer}
                readOnly={readOnly}
                path={[...path, "timer"]}
                forms={forms}
                tabEnabled={true}
                parentData={data[currentTab]}
              />
            )}
            {data[currentTab]?.fab ? (
              <Fab
                data={data[currentTab]?.fab}
                readOnly={readOnly}
                path={path}
                forms={forms}
                tabEnabled={true}
              />
            ) : data[currentTab]?.list?.fab ? (
              <Fab
                data={
                  data[currentTab]?.list?.fab ? data[currentTab]?.list?.fab : {}
                }
                readOnly={readOnly}
                path={[...path, "list"]}
                forms={forms}
                tabEnabled={true}
              />
            ) : null}
            <Modal openModal={openModalSwipe} tab={true}>
              <Swipe
                data={data[currentTab]?.swipe}
                path={path}
                handleOpenActions={handleOpenActions}
                readOnly={readOnly}
                forms={[...forms, ...hforms]}
                numForms={forms?.length}
              />
            </Modal>
          </DropTargetBody>
        )
      ) : (
        <DropTargetBody
          accept={Object.keys(ComponentsLibraries?.tab)}
          dragParent={path}
          className="drop-tab-empty"
        >
          <div className="empty-tab">
            {readOnly ? "No Tabs here" : "Drop here"}
          </div>
        </DropTargetBody>
      )}
    </div>
  );
}

export default Tab;
