import React, { useEffect, useState, useContext, useRef } from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { useSelector, useStore } from "react-redux";
import imxtools from "imxtools";
import Kpi from "../Kpi";
import "./index.scss";
import { navigateTo } from "../../services/navigationMiddleware";
import { handleComponentProp } from "../../services/handleComponentProperties";
import DropTargetRow from "../../services/DropTargetRow";
import { ComponentsLibraries } from "../../../../../services/common";
import DragItemApp from "../../../../ComponentsLibrary/DragItemApp";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import { AppContext } from "../../../../../services/AppContext";

function Row({
  data = {},
  indexRow = "",
  path = [],
  numRow,
  readOnly = true,
  currentPage,
  pages,
}) {
  let rendered = false;
  const kpiContainerRef = useRef(null);
  let store = useStore();
  const [listApps, setListApps] = useState([]);
  const [kpiPages, setKpiPages] = useState([]);

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );
  const { setKpiDetail, setBodyNotif } = useContext(AppContext);
  let view = useSelector(
    (state) => state?.entities?.deviceview?.settings?.view || "row"
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const handleNavigateTo = (event, indexApp, indexRow, app, data, numApp) => {
    event.stopPropagation();
    const rowPath =
      numRow === 1 ? [...path, "row"] : [...path, "row", indexRow.toString()];
    const newPath =
      numApp === 1 ? [...rowPath, app] : [...rowPath, app, indexApp.toString()];

    navigateTo(store, "List", { data, path: newPath });
  };

  useEffect(() => {
    setKpiPages(imxtools?.toArray(data?.kpi));
  }, [data.kpi]);

  useEffect(() => {
    setListApps(imxtools?.toArray(data?.app));
  }, [data]);
  useEffect(() => {
    try {
      const kpiContainer = kpiContainerRef.current;
      if (!kpiContainer) return;

      // Set scroll position to the beginning on mount and when pages change
      kpiContainer.scrollLeft = 0;

      const handleWheel = (event) => {
        event.preventDefault();
        const scrollSpeedFactor = 5;
        const newScrollPosition = event.deltaY / scrollSpeedFactor;
        kpiContainer.scrollLeft += newScrollPosition;
      };

      kpiContainer.addEventListener("wheel", handleWheel, {
        passive: false,
      });

      kpiContainer.scrollLeft =
        kpiContainer.scrollWidth - kpiContainer.clientWidth;
      return () => {
        kpiContainer.removeEventListener("wheel", handleWheel);
      };
    } catch (error) {
      console.error("WheelScroll PageNav", error);
    }
  }, []);
  return (
    <DropTargetRow
      accept={Object.keys(ComponentsLibraries?.row)}
      className="drop-row-zone"
      pages={pages}
      dropPath={
        numRow === 1 ? [...path, "row"] : [...path, "row", indexRow?.toString()]
      }
      dropTargetName={"row"}
      indexRow={indexRow}
      currentPage={currentPage}
      onClick={() => {
        setKpiDetail(false);
        setBodyNotif(false);
      }}
    >
      {imxtools?.toArray(data?.app)?.length ||
      imxtools?.toArray(data?.kpi)?.length ? (
        <div
          className={`row-body ${!readOnly && "edit"} ${
            isSelectedComponent(
              readOnly,
              null,
              currentPath,
              "row",
              numRow > 1 ? indexRow : null
            ) && "selectedComponent"
          } ${!readOnly && "hoverComp"} ${
            view?.toLowerCase() === "matrix" && data?.app && "matrix-row-body"
          }`}
          onClick={
            readOnly
              ? null
              : (event) => {
                  handleComponentProp(
                    event,
                    numRow === 1 ? null : indexRow?.toString(),
                    data,
                    path,
                    store,
                    "row"
                  );
                  setKpiDetail(false);
                  setBodyNotif(false);
                }
          }
        >
          {Object.keys(data)?.length > 0 ? (
            Object.keys(data).map((item, indexItem) => {
              if (item === "app") {
                rendered = true;
                return imxtools.toArray(data?.app)?.map((app, indexApp) => {
                  const numApp = imxtools?.toArray(data?.app)?.length;
                  const dragPath =
                    numRow === 1
                      ? numApp === 1
                        ? [...path, "row", "app"]
                        : [...path, "row", "app", indexApp?.toString()]
                      : numApp === 1
                      ? [...path, "row", indexRow?.toString(), "app"]
                      : [
                          ...path,
                          "row",
                          indexRow?.toString(),
                          "app",
                          indexApp?.toString(),
                        ];
                  return (
                    <DragItemApp
                      accept={["app", "kpi"]}
                      key={indexApp}
                      className={`${
                        view?.toLowerCase() === "matrix"
                          ? "matrix-app"
                          : "DragItemApp"
                      } ${
                        isSelectedComponent(readOnly, dragPath, currentPath) &&
                        "selectedComponent"
                      }`}
                      style={{
                        marginLeft: indexApp === 0 && "2%",
                        marginRight: numApp - 1 === indexApp && "2%",
                      }}
                      sort={true}
                      index={indexApp}
                      app={app}
                      hoveredComponent={"row"}
                      listApps={listApps}
                      setListApps={setListApps}
                      component={"app"}
                      dragData={app}
                      dragPath={dragPath}
                      dragParentPath={
                        numRow === 1
                          ? [...path, "row"]
                          : [...path, "row", indexRow?.toString()]
                      }
                    >
                      <div
                        key={indexApp}
                        className={`appRow-container ${
                          isSelectedComponent(
                            readOnly,
                            dragPath,
                            currentPath
                          ) && "selectedComponent"
                        }`}
                        onClick={(e) => {
                          handleComponentProp(
                            e,
                            imxtools?.toArray(data?.app).length <= 1
                              ? null
                              : indexApp?.toString(),
                            app,
                            numRow === 1
                              ? [...path, "row"]
                              : [...path, "row", indexRow?.toString()],
                            store,
                            "app",
                            imxtools?.toArray(data?.app)
                          );
                          setBodyNotif(false);
                        }}
                        onDoubleClick={(event) =>
                          handleNavigateTo(
                            event,
                            indexApp,
                            indexRow,
                            "app",
                            app,
                            numApp
                          )
                        }
                      >
                        <div className="icon">
                          <Icon
                            icon={app?.icon ? app?.icon : "map"}
                            size={20}
                          />
                        </div>
                        <div className="app-title">
                          <div className="title">
                            {app?.label
                              ? getLabelByLanguage(language, app)
                              : "Add Label"}
                          </div>
                        </div>
                      </div>
                    </DragItemApp>
                  );
                });
              } else if (item === "kpi") {
                rendered = true;

                return (
                  <div
                    key={indexItem}
                    ref={kpiContainerRef}
                    className={`kpi-container  ${
                      isSelectedComponent(
                        readOnly,
                        [...path, "row", "kpi"],
                        currentPath
                      )
                        ? `selectedComponent`
                        : `unSelectedComponent`
                    } `}
                    style={{
                      paddingBottom: !readOnly && "2px",
                      flexDirection: !data?.kpi?.type && "column",
                      alignItems: !data?.kpi?.type && "center",
                    }}
                    onClick={(e) => {
                      setKpiDetail(false);
                      setBodyNotif(false);
                      handleComponentProp(
                        e,
                        null,
                        data?.kpi,
                        [...currentPath, "kpi"],
                        store,
                        "kpi"
                      );
                    }}
                  >
                    {kpiPages?.length > 0 ? (
                      kpiPages?.map((kpi, indexKpi) => {
                        const numKpi = imxtools.toArray(data.kpi)?.length;
                        const dragPath =
                          numRow === 1
                            ? numKpi === 1
                              ? [...path, "row", "kpi"]
                              : [...path, "row", "kpi", indexKpi?.toString()]
                            : numKpi === 1
                            ? [...path, "row", indexRow?.toString(), "kpi"]
                            : [
                                ...path,
                                "row",
                                indexRow?.toString(),
                                "kpi",
                                indexKpi?.toString(),
                              ];
                        return (
                          <DragItemApp
                            accept={["app", "kpi"]}
                            key={indexKpi}
                            sort={true}
                            index={indexKpi}
                            className="Kpi-Drag"
                            app={kpi}
                            hoveredComponent={"row"}
                            listApps={kpiPages}
                            setListApps={setKpiPages}
                            component={"kpi"}
                            dragData={kpi}
                            dragPath={dragPath}
                            dragParentPath={
                              numRow === 1
                                ? [...path, "row"]
                                : [...path, "row", indexRow?.toString()]
                            }
                            style={{
                              width: !data?.kpi?.type && "100%",
                            }}
                          >
                            <div
                              key={indexKpi}
                              className="Kpi-DragItemApp"
                              style={{
                                width: !data?.kpi?.type && "100%",
                              }}
                              onClick={(event) => {
                                handleComponentProp(
                                  event,
                                  numKpi > 1 ? indexKpi?.toString() : null,
                                  kpi,
                                  numRow === 1
                                    ? [...path, "row"]
                                    : [...path, "row", indexRow?.toString()],
                                  store,
                                  "kpi"
                                );
                                setKpiDetail(false);
                                setBodyNotif(false);
                              }}
                            >
                              <Kpi
                                data={kpi}
                                index={indexKpi}
                                readOnly={readOnly}
                                currentPath={currentPath}
                                path={
                                  numRow === 1
                                    ? numKpi === 1
                                      ? [...path, "row", "kpi"]
                                      : [
                                          ...path,
                                          "row",
                                          "kpi",
                                          indexKpi?.toString(),
                                        ]
                                    : numKpi === 1
                                    ? [
                                        ...path,
                                        "row",
                                        indexRow?.toString(),
                                        "kpi",
                                      ]
                                    : [
                                        ...path,
                                        "row",
                                        indexRow?.toString(),
                                        "kpi",
                                        indexKpi?.toString(),
                                      ]
                                }
                              />
                            </div>
                          </DragItemApp>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
            })
          ) : (
            <DragItemApp
              accept={["app", "kpi"]}
              className="Drag-empty-row"
              dataSorted={listApps}
              sort={true}
              listApps={listApps}
              setListApps={setListApps}
              hoveredComponent={"row"}
              component={"app"}
              style={{
                display: "flex",
                width: "100%",
                height: 30,
                backgroundColor: "#CDF7F6",
                alignItems: "center",
                justifyContent: "center",
              }}
              dragParentPath={
                numRow === 1
                  ? [...path, "row"]
                  : [...path, "row", indexRow?.toString()]
              }
              onClick={() => setBodyNotif(false)}
            >
              Row
            </DragItemApp>
          )}
        </div>
      ) : readOnly ? null : (
        <div
          style={{ minHeight: 100 }}
          className={`${"void-row"} ${readOnly ? "" : "hovered"} ${
            !readOnly && "edit"
          } ${
            isSelectedComponent(
              readOnly,
              null,
              currentPath,
              "row",
              numRow > 1 ? indexRow : null
            ) && "selectedComponent"
          } `}
          onClick={(event) => {
            handleComponentProp(
              event,
              numRow === 1 ? null : indexRow?.toString(),
              data,
              path,
              store,
              "row"
            );
            setBodyNotif(false);
          }}
        >
          <DragItemApp
            dataSorted={listApps}
            sort={true}
            accept={["app", "kpi"]}
            listApps={listApps}
            setListApps={setListApps}
            hoveredComponent={"row"}
            component={"app"}
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
            dragParentPath={
              numRow === 1
                ? [...path, "row"]
                : [...path, "row", indexRow?.toString()]
            }
          >
            <DropTargetRow
              accept={["app", "kpi"]}
              pages={pages}
              dropPath={
                numRow === 1
                  ? [...path, "row"]
                  : [...path, "row", indexRow?.toString()]
              }
              dropTargetName={"row"}
              indexRow={indexRow}
              currentPage={currentPage}
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setBodyNotif(false)}
            >
              Row
            </DropTargetRow>
          </DragItemApp>
        </div>
      )}
    </DropTargetRow>
  );
}

export default Row;
