import "./index.scss";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { useSelector } from "react-redux";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import { useContext } from "react";
import { AppContext } from "../../../../../../services/AppContext";

const PageBtn = ({
  index = null,
  handlePageClick,
  handleMouseEnter,
  handleMouseLeave,
  page,
  isGIS = false,
}) => {
  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  const { setBodyNotif } = useContext(AppContext);
  return (
    <div
      key={index}
      className="page-nav"
      onClick={(e) => {
        handlePageClick(e, index);
        setBodyNotif(false);
      }}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <Icon
        className="page-icon"
        icon={
          page?.icon
            ? page?.icon
            : page?.gis
            ? page?.gis?.icon
              ? page?.gis?.icon
              : "map"
            : "apps-icon"
        }
        size={15}
      />
      <div className="page-title">
        {page?.label
          ? getLabelByLanguage(language, page)
          : page?.gis
          ? page?.gis?.label
            ? getLabelByLanguage(language, page?.gis)
            : "Add label"
          : isGIS
          ? "Add GIS Label"
          : "Add label"}
      </div>
    </div>
  );
};
export default PageBtn;
